import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Cx from "../../../style/custom.module.scss";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import Headerhome from "layouts/hearderhome/Headerhome";

/* import bgImage from "assets/images/bg-about-us.jpg";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes";
import MKButton from "components/MKButton"; */

const CorporateReconstructions = () => {
  return (
    <>
      {/* <DefaultNavbar
      routes={routes}
      action={{
        type: "external",
        route: "https://www.creative-tim.com/product/agfinance-hub-react",
        label: "free download",
        color: "default",
      }}
    /> */}
      <Headerhome />
      <section className={`${Cx.bg_Blk_Clr} position-relative`}>
        <MKBox
          width="100%"
          className={`${Cx.section_about}`}
          sx={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={12}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className={Cx.contantabout}
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <div className={`${Cx.main_heading} ${Cx.headerpart}`}>
                <MKTypography>
                  <h1>Corporate Reconstructions</h1>
                </MKTypography>
                <MKTypography>
                  <h2>AG FINANCE AND TECHNOLOGY GROUP LTD</h2>
                </MKTypography>
              </div>
            </Grid>
          </Container>
        </MKBox>
        <div className="container">
          <Card className={`${Cx.bg_Blk_Clr} shadow-none `}>
            <div className={`${Cx.abutotextarea} ${Cx.bg_Blk_Clr}`}>
              <div className={`${Cx.heading_textarea} pb-5`}>
                <p>
                  A corporate reconstruction can take many forms, however it is most commonly used to either form or reorganise a group of companies. This article will focus on forming a group and reorganising a group of companies. This article will focus on the reorganisation of an existing group.
                </p>
                <p className="pt-5"><strong>Holding Company Benefits</strong></p>
                <p>
                  Forming a group involves inserting a holding company above a trading company, or a subsidiary below the trading company. In the case of the holding company, it is possible for the shareholders of the trading company to carry out a share for share exchange to move their shareholdings to the new holding company, thereby transferring their holdings to the total group, rather than just the trading company.
                </p>
                <p>
                  This transaction requires advanced clearance from HMRC and this process should be undertaken by a tax professional to ensure that the requirements are adequately met before the application is made.
                </p>
                <p>
                  A business may wish to add a holding company for a few key reasons:
                </p>

                <ul className={Cx.showListDots}>
                  <li>Asset protection - the holding company could hold an asset such as a commercial property, separating it from the trading company.</li>
                  <li>
                    Trading status - if the business has or wishes to have non-trading activity such as investments, carrying out those investments in a holding company allows the trading company to protect its trading status, this is key for BADR.
                  </li>
                  <li>
                    Legal protection - the holding company adds a layer of protection of the shareholders in the event of adverse proceedings in the trading subsidiary.
                  </li>
                  <li>
                    Flexibility - a holding company would allow the creation of multiple subsidiaries to hive off different trades or parts of a trade.
                  </li>
                  <li>
                    Substantial Shareholding Exemption - where a company in the UK holds shares in another company and meets the criteria, any gain on the sale of the qualifying subsidiary may be exempt from Corporation Tax.
                  </li>
                </ul>

                <p className="pt-5"><strong>Group Structure Benefits</strong></p>
                <p>
                  A group for tax purposes is generally defined as a parent company, together with its 51% owned subsidiaries. There can be subgroups where a subsidiary has subsidiaries of its own.
                </p>
                <p>
                  There are tax benefits to maintaining 75% ownership, this is a requirement to be in a capital gains group, allowing no gain no loss transfers of assets between group companies.
                </p>

                <p className="pt-5"><strong>Advantages</strong></p>
                <p>There are many advantages to adopting a group company structure. These include:</p>
                <ul className={Cx.showListDots}>
                  <li>
                    Centralised management and administration, with all or most decision-making resting with the parent company. It can create greater financial and operational efficiency.
                  </li>
                  <li>
                    Reduced risk. Risk is shared amongst companies in the group structure rather than resting in just one business.
                  </li>
                  <li>
                    Reduced liability. Parent companies are generally not liable for the debt of its subsidiaries, meaning that if a subsidiary collapses, the parent company and its shareholders will be unaffected.
                  </li>
                </ul>

                <p className="pt-3"></p>
                <p>Advantages do need to be weighed against the disadvantages of a group structure. Business owners should consider:</p>
                <ul className={Cx.showListDots}>
                  <li>
                    Raising funds. External investors may be reluctant to invest in a subsidiary company with complex ownership structures.
                  </li>
                  <li>
                    Complicated finances. Group structures can quickly complicate finances which can become opaque. There will be increased accountancy costs.
                  </li>
                  <li>
                    Where assets have been moved between group companies as a no gain no loss transfer, there could be a degrouping charge if the asset or new owning company exits the group.
                  </li>
                  <li>
                    Administration. Where the group exceeds the medium company thresholds, consolidated accounts may be required which can add complexity and costs to the annual compliance cycle.
                  </li>
                </ul>

                <p className="pt-5"><strong>Tax Advantages</strong></p>
                <p>
                  Whilst each business within a group structure will be required to pay corporation tax, VAT and other relevant taxes, a group structure can offer attractive tax advantages and efficiencies. These include:
                </p>

                <ul className={Cx.showListDots}>
                  <li>
                    The ability to transfer assets between companies in a group structure without triggering a disposal charged against Corporation Tax on any gains.
                  </li>
                  <li>
                    Groups can account for Corporation Tax through one company in the group through the ‘group payment arrangement’.
                  </li>
                  <li>
                    Companies in a group can account for VAT at a group level, with supplies between group companies exempt and with just one entity paying VAT on behalf of the group.
                  </li>
                  <li>
                    Losses of group companies can be surrendered to profit-making group companies, potentially reducing Corporation Tax as a group.
                  </li>
                </ul>


                <p className="pt-5"><strong>Corporate Reconstructions: Group Reorganisations</strong></p>
                <p>
                  A group reorganisation is the term used to describe the transfer of assets, which includes a business or part of a business, or the transfer of shares usually from one company to another.
                </p>
                <p>
                  Some common reasons for a group reorganisation include:
                </p>

                <p className="pt-3">1. To simplify a corporate structure</p>
                <p>
                  Many group structures develop over time due to historic acquisitions. Often there can be several group companies carrying on similar activities.
                </p>
                <p>
                  A simplified group structure can save management time, improve efficiency and reduce compliance costs.
                </p>
                <p>
                  Having more companies than needed can bring additional compliance costs for example for preparation of accounts, Companies’ House and tax filings. It can also absorb management time in matters that do not generate profit, for example where intragroup transactions have to be monitored and recorded.
                </p>

                <p className="pt-3">2. Prepare part or all of the group for sale</p>
                <p>
                  Simplifying a group structure can make the group more attractive for a sale. For example, eliminating unnecessary dormant companies and generally tidying up your group can mean a buyer’s due diligence has to be carried out on fewer entities. A simple group structure can also contribute to an overall lower risk control environment assessment when a buyer is looking for protection against historic tax matters.
                </p>
                <p>
                  Alternatively, where only part of a business is to be sold, a group reorganisation may involve transferring either the part to be sold, or sometimes the part of the business to be retained, into a new company by ’hiving down’ the assets to be sold, or demerging the assets into a new separately owned entity.
                </p>
                <p className="pt-3">3. Structuring to support expansion or acquisitions</p>
                <p>
                  As groups grow, they may need a group holding structure to raise external funding or to provide some structural protection for the established part of the business from new ventures. This can typically involve putting in place a group holding company, and possibly intermediate holding companies and a group finance company.
                </p>

                <p className="pt-3">4. Succession planning</p>
                <p>
                  In family owned businesses it can be quite common that second or third generation family members may have interests in different parts of the business, or family members may want to take the different parts of the business in different directions. The same can occur with shareholders who are not related. A reorganisation involving a demerger can facilitate the different personal objectives of shareholders.
                </p>

                <p className="pt-3">5. Separate investing and trading activities</p>
                <p>
                  The use of separate entities for property can be attractive when raising bank debt as it allows the risks associated with the trade to be ring-fenced within the trading company. This can also be a big help with exit or succession planning as shareholders may wish to divest/sell the trading business but retain an interest in the property.
                </p>
                <p className="pt-3">6. Protect assets</p>
                <p>
                  As with the formation of a group, a group reorganisation can mean that assets or businesses that shareholders want to protect can be held separately from the riskier parts of their business.
                </p>
                <p>
                  How to carry out a group reorganisation?
                </p>
                <p>
                  A group reorganisation typically involves transferring one or more trades and assets, a process often referred to as a ‘hive down’. Tax considerations are crucial in determining the appropriate structure for this transfer. Generally, a hive down can be executed in a tax-neutral manner, provided both the transferor and transferee entities belong to a UK tax group. For companies not in the same UK tax group but under common control, specific tax rules may allow many assets to be transferred tax neutrally. However, capital and intangible assets must be transferred at market value for direct tax purposes, requiring careful consideration to avoid triggering taxable gains or profits. In a corporate group, it is usually possible to transfer property assets without incurring Stamp Duty Land Tax (SDLT), although attention must be paid to potential clawback events.
                </p>
                <p>
                  Executing a hive down requires careful and systematic planning to avoid unexpected tax liabilities and to identify any necessary tax claims or elections for UK corporation tax returns to achieve tax neutrality. Accounting implications must also be considered, and there are legal requirements to document the transfers properly.
                </p>

                <p className="pt-5"><strong>Areas that typically need to be considered carefully on a hive down include:</strong></p>
                <ul className={Cx.showListDots}>
                  <li>VAT registrations and ensuring the hive down meets the conditions to be a Transfer or a Going Concern (TOGC) for VAT purposes.
                  </li>
                  <li>Tax trading losses and ensuring they remain available to carry forward post hive down.</li>
                  <li>Intercompany balances and avoiding taxable waivers if they are not cleared in advance.</li>
                  <li>Capital allowances planning and property transfers.</li>
                  <li>Moving assets from stock to fixed assets thereby creating a dry tax charge.</li>
                  <li>Creating potential future SDLT clawbacks.</li>
                  <li>	Future de-grouping gains - although recent changes to the SSE rules mean planning can often mitigate de-grouping gains</li>
                </ul>

                <p className="pt-5"><strong>New holding companies and demergers:</strong></p>
                <p>
                  Where a group reorganisation involves new companies and/or a demerger into separate groups it is likely that tax clearances from HMRC will be recommended. HMRC has 30 days to respond to any clearance application so obtaining a tax clearance needs to be built into a project timetable.
                </p>

                <p className="pt-5"><strong>What are the typical steps to carry out a group reorganisation?</strong></p>
                <ul className={Cx.showListDots}>
                  <li>Carry out a feasibility study that sets out how the reorganisation will be achieved and highlighting areas for further consideration.</li>
                  <li>Apply for tax clearances (if applicable).</li>
                  <li>Detailed steps plan - this report details the steps that need to happen to effect the reorganisation. This can also help legal advisers to map out the documentation required for each step.</li>
                  <li>Implementation of steps plan.</li>
                  <li>Reporting to HMRC - this is an important step to ensure that all applicable reporting and elections are carried out within the time limits required.</li>
                </ul>

                <p className="pt-5"><strong>Summary</strong></p>
                <p>
                  Overall, corporate reconstructions are an excellent method of improving a business structure to allow for greater flexibility, reduce ongoing compliance costs and enhance tax efficiency.
                </p>
                <p>
                  Our experts in advising on corporate structuring and would be delighted to assist you with your structuring needs.
                </p>
                <p>
                  If you are unsure if restructuring could be useful for your business, please feel free to reach out to Ben Hawkins at <a href="mailto:info@levygera.com">info@levygera.com</a>  for a complimentary discovery call.
                </p>
                <p>
                  This article was written by Ben Hawkins, our tax lead and a Chartered Accountant and Chartered Tax Adviser with more than 10 years of experience in UK corporate tax. One of his areas of expertise is Corporate Reconstruction.
                </p>
              </div>
            </div>
          </Card>
        </div>
        <div className="position-relative pt-5">
          <div className="container-fluid px-0 mx-0 ">
            <div className={Cx.bannertopimage1}>
              <div className={Cx.paddingarea1}></div>
            </div>
          </div>
        </div>
      </section>

      <MKBox pt={0} px={0} mt={0}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default CorporateReconstructions;
