import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Cx from "../../../style/custom.module.scss";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import Headerhome from "layouts/hearderhome/Headerhome";

const AutumnBudget = () => {
  return (
    <>
      {/* <DefaultNavbar
      routes={routes}
      action={{
        type: "external",
        route: "https://www.creative-tim.com/product/agfinance-hub-react",
        label: "free download",
        color: "default",
      }}
    /> */}
      <Headerhome />
      <section className={`${Cx.bg_Blk_Clr} position-relative`}>
        <MKBox
          width="100%"
          className={`${Cx.section_about}`}
          sx={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={12}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className={Cx.contantabout}
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <div className={`${Cx.main_heading} ${Cx.headerpart}`}>
                <MKTypography>
                  <h1>Autumn Budget: Understanding the UK Corporate Tax Roadmap Ahead</h1>
                </MKTypography>
                <MKTypography>
                  <h2>AG FINANCE AND TECHNOLOGY GROUP LTD</h2>
                </MKTypography>
              </div>
            </Grid>
          </Container>
        </MKBox>
        <div className="container">
          <Card className={`${Cx.bg_Blk_Clr} shadow-none `}>
            <div className={`${Cx.abutotextarea} ${Cx.bg_Blk_Clr}`}>
              <div className={`${Cx.heading_textarea} pb-5`}>
                <p>
                  The UK government recently introduced a Corporate Tax Roadmap which highlights several
                  key commitments that not only help maintain the UK&#39;s competitive edge but also
                  offer businesses insights into upcoming tax changes they need to prepare for. Below,
                  we&#39;ll break down the essential points of the roadmap, including tax rate changes,
                  capital allowances, R&D provisions, and the significant upcoming increase in
                  employers&#39; National Insurance Contributions (NICs). By understanding these
                  updates, businesses can better prepare for the changes and what to expect over the
                  coming years.
                </p>
                <p className="pt-5">
                  <strong>Corporate Tax Rate and Small Profits Rate: Stability for Businesses</strong>
                </p>
                <p>
                  A primary component of the roadmap is the decision to keep the main corporation tax
                  rate steady at 25%. For businesses making smaller profits, the small profits rate will
                  remain at 19%, offering relief to smaller enterprises. This consistency is designed to
                  help companies plan with confidence, knowing that there will be no major fluctuations
                  in tax rates in the near term.
                </p>

                <p className="pt-5"><strong>Capital Allowances: Supporting Investments and Growth</strong></p>
                <p>
                  The budget addresses capital allowances, ensuring businesses can continue to invest in
                  assets and equipment with the benefit of full expensing. Here’s what remains in place:
                </p>

                <ul className={Cx.showListDots}>
                  <li className="text-white">
                    <b>Full Expensing:</b> Companies can fully deduct qualifying capital expenditures,
                    which means they can immediately write off the costs of investments in certain
                    assets.
                  </li>
                  <li className="text-white">
                    <b>£1 Million Annual Investment Allowance:</b> This benefit will remain available,
                    allowing businesses to make capital investments up to £1 million with immediate tax
                    deductions.
                  </li>
                </ul>
                <p>
                  These allowances help support businesses as they invest in growth, equipment, and
                  technology. Maintaining these provisions ensures that companies have a tax-friendly
                  way to expand operations and boost productivity.
                </p>

                <p className="pt-5"><strong>Stability in R&D and Patent Box Reliefs</strong></p>
                <p>
                  The UK’s R&D and patent box regimes, which offer tax relief on qualifying research and
                  intellectual property income, remain in place. This stability is especially important
                  for innovative companies that rely on these incentives to fund R&D activities and
                  protect patents.
                </p>
                <p>
                  The government is also launching consultations to examine potential tax treatment
                  adjustments for pre-development costs, as well as reviewing the effectiveness of land
                  remediation relief.
                </p>

                <p className="pt-5">
                  <strong>International Tax Rules Modernisation: Aligning with Global Standards</strong>
                </p>
                <p>
                  There was mention of publishing a technical consultation on draft legislation to
                  modernise UK international tax rules, covering transfer pricing, permanent
                  establishment, and diverted profits tax, with a potential focus on removing UK-to-UK
                  transfer pricing and adjusting transfer pricing legislation further.
                </p>

                <p className="pt-5">
                  <strong>The Significant Change: Employers&#39; NICs Increase Starting April 2025</strong>
                </p>
                <p>
                  One of the most impactful changes in the roadmap is the increase in employers&#39;
                  National Insurance Contributions (NICs), scheduled for April 2025. Here’s what
                  businesses need to know:
                </p>

                <ul className={Cx.showListDots}>
                  <li className="text-white">
                    <b>New Rate: </b> The NIC rate for employers will increase from <b>13.8% to 15%.</b>
                  </li>
                  <li className="text-white">
                    <b>Lowered Threshold:</b> The threshold for employer NICs will drop from £9,100 to{" "}
                    <b>£5,000.</b> This change means more of a business’s payroll costs will be subject
                    to NICs.
                  </li>
                </ul>

                <p>
                  To offset the financial burden on smaller businesses, the government is raising the
                  employment allowance from <b>£5,000 to £10,500.</b> Previously, this allowance was
                  limited to businesses with NIC bills under £100,000, but this cap will now be removed,
                  making it accessible to all employers. While this increase in NICs is expected to
                  generate <b>£25 billion annually</b> for the government, the adjustment in the
                  employment allowance aims to ease the impact on small businesses and encourage
                  continued hiring and growth.
                </p>

                <p className="pt-5"><strong>Updates on R&D Intensive Scheme (RDIS)</strong></p>
                <p>
                  The budget made clarifications on the R&D Intensive Scheme (RDIS), a tax relief
                  program designed to encourage significant investment in research and development.
                  Initially, the Finance Act 2024 excluded certain R&D expenditure from counting toward
                  the R&D intensity threshold, which determines a company’s eligibility for RDIS.
                  However, recent budget documentation confirmed that Research & Development Expenditure
                  Credit (RDEC) qualified spending will indeed be included in the intensity calculation,
                  effective from April 1, 2023.
                </p>

                <p>
                  For businesses aiming to qualify under the Enhanced R&D Intensive Support (ERIS), the
                  required threshold for R&D intensity will be reduced from <b>40% to 30%</b> starting
                  April 1, 2024. This reduction makes it easier for companies to qualify as R&D
                  intensive, thus expanding access to these valuable tax incentives.
                </p>

                <p className="pt-5"><strong>How We Can Help</strong></p>
                <p>
                  Understanding the complexities of the UK’s corporate tax landscape can be challenging,
                  especially with evolving regulations and detailed thresholds. Our tax team, made up of
                  experts from some of the world’s top financial firms, provides the same high level of
                  insight without the high fees often associated with big consultancies.
                </p>

                <p>
                  If you need guidance on how these changes could affect your business, or if you’d like
                  help with tax planning or R&D claims, feel free to reach out. We offer complimentary
                  consultations to ensure you understand the options available and are prepared for the
                  road ahead.
                </p>

                <p>
                  For expert advice, contact us at{" "}
                  <a href="mailto:info@agfinancehub.com">info@agfinancehub.com.</a>
                </p>
              </div>
            </div>
          </Card>
        </div>
        <div className="position-relative pt-5">
          <div className="container-fluid px-0 mx-0 ">
            <div className={Cx.bannertopimage1}>
              <div className={Cx.paddingarea1}></div>
            </div>
          </div>
        </div>
      </section>

      <MKBox pt={0} px={0} mt={0}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default AutumnBudget;
