import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Cx from "../../../style/custom.module.scss";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import Headerhome from "layouts/hearderhome/Headerhome";

function TermsCondition() {
  return (
    <>
      <Headerhome />
      <section className={`${Cx.section_about} position-relative`}>
        <div className="container-fluid mx-0 px-0">
          <div className="container m-auto">
            <div className={Cx.headerpart}>
              <div className="row m-auto">
                <h1>User Terms And Conditions</h1>
                <h2>AG Finance and Technology Group Ltd</h2>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className={`${Cx.abutotextarea} ${Cx.terms_policyApplied} pt-0`}>
                <MKTypography variant="body1" className={Cx.heading_textarea}>
                  <p className="mt-0">1. <strong>Introduction</strong></p>

                  <div className="ps-4 pb-5">
                    <p>
                      1. These Terms and Conditions (&#34;Terms&#34;) govern your use of the AG Finance and Technology Group Ltd (&#34;AG Finance Hub&#34;) website (&#34;Website&#34;). By accessing or using our Website, you confirm that you have read, understood, and agree to be bound by these Terms. If you disagree with any part of these Terms, you must not use the Website.
                    </p>
                    <p>
                      2. The terms &#34;we,&#34; &#34;us,&#34; and &#34;our&#34; refer to AG Finance and Technology Group Ltd, a company registered in England and Wales (company number 15901932), with its registered office at 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ. The terms &#34;you&#34; and &#34;your&#34; refer to the user of the Website.
                    </p>
                    <p>
                      3. These Terms incorporate our Privacy Policy and Cookies Policy by reference. By using the Website, you consent to the collection, use, and sharing of your information as outlined in these policies.
                    </p>
                  </div>
                  <p>
                    1. <strong>Use of the Website</strong>
                  </p>
                  <div className="ps-4">
                    <p>
                      1. The Website is intended for use by individuals who are at least 18 years of age and     representatives of UK-based businesses. By using the Website, you confirm that you meet these criteria.
                    </p>
                    <p>
                      2. Access to certain areas of the Website may be restricted. We reserve the right to restrict access, suspend, or disable accounts without prior notice if we believe a user has breached these Terms.
                    </p>
                    <p>
                      3. You agree not to:
                    </p>
                  </div>
                  <p>
                    1. Use the Website for unlawful, fraudulent, or harmful purposes.
                  </p>
                  <p>
                    2. Upload, share, or submit false, fraudulent, or misleading financial or technical documents, including bank statements and CT600s.
                  </p>
                  <p>
                    3. Attempt to circumvent or interfere with the services provided by us or our strategic partners.
                  </p>
                  <p className="pb-5">
                    4. Use the Website to transmit malicious software, unsolicited communications, or spam.
                  </p>

                  <p>
                    1. <strong>Document Submission and Consent</strong>
                  </p>
                  <div className="ps-4 pb-5">
                    <p>
                      1. By using the Website, you agree that any documents and information you submit (including bank statements, VAT returns, and CT600s) may be shared with our strategic partners, brokers, and lenders solely for the purpose of assessing and delivering financing solutions.
                    </p>
                    <p>
                      2. You expressly consent to the processing, transfer, and use of your data for eligibility assessments and the provision of financing options by third-party entities we collaborate with.
                    </p>
                    <p>
                      3. You expressly consent to the processing, transfer, and use of your data for eligibility assessments and the provision of financing options by third-party entities we collaborate with.
                    </p>
                  </div>

                  <div >
                    <p>
                      1. <strong>Non-Circumvention</strong>
                    </p>
                    <div className="ps-4 pb-5">
                      <p>
                        1. By engaging with our Website, you agree not to circumvent or impair our ability to provide financing solutions. This includes contacting lenders or partners directly without our involvement.
                      </p>
                      <p>
                        2. If you breach this clause, you agree to pay us any fees we would have earned from the lender or partner had the service proceeded through our Website.
                      </p>
                      <p>
                        3. Even if you do not provide us with complete information necessary to deliver the service but proceed directly with lenders or partners we introduced, you remain liable for the associated fees.
                      </p>
                    </div>
                  </div>

                  <p>
                    1. <strong>Disclaimer and Liability</strong>
                  </p>
                  <div className="ps-4 pb-5">
                    <p>
                      1. AG Finance and Technology Group Ltd is not a brokerage or financial advisory firm. Our Website serves as a platform to connect users with lenders or partners who may offer financing solutions.
                    </p>
                    <p>
                      2. We do not guarantee the accuracy, availability, or terms of financing options provided through the Website, as these are subject to changes by the lenders.
                    </p>
                    <p>
                      3. You are encouraged to seek independent financial, legal, or tax advice before making decisions based on information obtained from the Website or entering into agreements with lenders.
                    </p>
                    <p>
                      4. AG Finance and Technology Group Ltd disclaims all liability for financial decisions or agreements entered into by you based on Website-provided information.
                    </p>
                    <p>
                      5. Our liability is capped at the fees we would have earned from the transaction facilitated through our Website.
                    </p>
                    <p>
                      6. By using the Platform, you agree to indemnify, defend, and hold harmless AG Finance and Technology Group Ltd, its affiliates, and its representatives from and against any and all claims,
                      damages, losses, liabilities, and expenses (including legal fees) arising from third-party claims as a result of your use of the Platform or any breach of these Terms.
                    </p>
                  </div>

                  <p>
                    1. <strong>Intellectual Property</strong>
                  </p>
                  <div className="ps-4 pb-5">
                    <p>
                      1. All content on the Website, including but not limited to logos, software, design, text, and graphics, is the intellectual property of AG Finance and Technology Group Ltd or its licensors.
                    </p>
                    <p>
                      2. You are granted a limited, non-exclusive, and non-transferable license to use the Website for its intended purposes. Unauthorized use, reproduction, or distribution of the Website&#39;s
                      content is strictly prohibited.
                    </p>
                  </div>
                  <p>
                    1. <strong>Payment and Refunds</strong>
                  </p>
                  <p className="ps-4 pb-5">
                    AG Finance and Technology Group Ltd does not process payments or refunds through the Website. All financial transactions occur directly between users and lenders or partners.
                  </p>
                  <p>
                    1. <strong>Termination and Suspension</strong>
                  </p>
                  <div className="ps-4 pb-5">
                    <p>
                      1. We reserve the right to suspend or terminate your access to the Website at any time, with or without cause, including but not limited to instances of fraudulent activity, misuse, or breach of these Terms.
                    </p>
                    <p>
                      2. Users are responsible for maintaining the security of their accounts, including passwords and third-party login credentials.
                    </p>
                  </div>
                  <p>
                    1. <strong>Updates and Communication</strong>
                  </p>
                  <div className="ps-4 pb-5">
                    <p>
                      1. AG Finance and Technology Group Ltd reserves the right to update or revise these Terms at any time. Users will be notified of material changes via email or updates published on the Website.
                    </p>
                    <p>
                      2. By providing your mobile number, you consent to receive notifications, updates, and communication from AG Finance and Technology Group Ltd via SMS or mobile notifications. You may opt-out at any
                      time by contacting us or using the opt-out mechanism provided in such communications.
                    </p>
                    <p>
                      3. Continued use of the Website following any changes to the Terms constitutes acceptance of the revised Terms.
                    </p>
                  </div>
                  <p>
                    1. <strong>Governing Law and Jurisdiction</strong>
                  </p>
                  <div className="ps-4 pb-5">
                    <p >
                      1. These Terms are governed by and construed in accordance with the laws of England and Wales.
                    </p>
                    <p>
                      2. Any disputes arising from or related to these Terms shall fall under the exclusive jurisdiction of the courts of England and Wales.
                    </p>
                  </div>

                  <p>
                    1. <strong>Dispute Resolution</strong>
                  </p>
                  <div className="ps-4 pb-5">
                    <p>
                      1. Users are encouraged to resolve disputes with us through negotiation or mediation. If disputes cannot be resolved, they will be settled through arbitration or the courts.
                    </p>
                    <p>
                      2.Users initiating disputes shall bear all associated legal costs, where legally permissible.
                    </p>
                  </div>

                  <p>
                    1. <strong>Cookies and Tracking</strong>
                  </p>
                  <div className="ps-4 pb-5">
                    <p>
                      1. By using the Website, you consent to the use of cookies and similar tracking technologies as outlined in our Cookies Policy.
                    </p>
                    <p>
                      Cookies are used to enhance user experience, track Website usage, and improve services. For details on managing cookies, please refer to our Cookies Policy.
                    </p>
                  </div>

                  <p>
                    1. <strong> Entire Agreement</strong>
                  </p>
                  <p className="ps-4">
                    These Terms represent the entire agreement between you and AG Finance and Technology Group Ltd regarding your use of the Website and supersede any prior agreements or understandings.
                  </p>
                </MKTypography>

              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid px-0 mx-0 ">
          <div className={Cx.bannertopimage1}>
            <div className={Cx.paddingarea1}></div>
          </div>
        </div>
      </section>

      <MKBox px={0}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default TermsCondition;
