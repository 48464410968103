import React, { useRef, useEffect, useState } from "react";
import MKBox from "components/MKBox";
import videoSource from "assets/images/videos/AGFinancehub.mp4";
import videoFinance from "assets/images/videos/AGProduct.mp4";
import footerRoutes from "footer.routes";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Cx from "./page.module.scss";
import Headerhome from "layouts/hearderhome/Headerhome";
import Name1 from "../../assets/images/blogs/IndependenetArticle.png";
import Name2 from "../../assets/images/blogs/IndependentWeb.png";
import Name3 from "../../assets/images/blogs/InflationArticle.png";
import Blog1 from "../../assets/images/blogs/reserch.jpg";
import Blog2 from "../../assets/images/blogs/autumnbudget.png";
import Blog3 from "../../assets/images/blogs/corporateblog.png";
import Bigimage1 from "../../assets/images/shapes/loginNew.svg";
import Bigimage2 from "../../assets/images/shapes/serviceNew.svg";
import Bigimage3 from "../../assets/images/shapes/NewDashboard.svg";
import Coverimage from "../../assets/images/products/ClientVideoCover.png";
import AiProduct2 from "../../assets/images/products/product-owl2.jpg";
import Aplication from "../../assets/images/products/apllicationsstatuss.png";
import $ from "jquery";
window.$ = window.jQuery = $;
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";

const Home = () => {
  const location = useLocation();
  let code = location?.search?.split("?code=")[1]?.split("&")[0];
  let userId = localStorage.getItem("userId");
  const navigate = useNavigate();

  useEffect(() => {
    const selectedStartDate = localStorage.getItem("selectedStartDate");
    const selectedEndDate = localStorage.getItem("selectedEndDate");
    const fetchData = async () => {
      try {
        const formData = new FormData();
        formData.append("code", code);
        formData.append("user", userId);
        const response = await fetch(
          `https://api.agfinancehub.com/api/xero/?start_date=${selectedStartDate}&end_date=${selectedEndDate}`,
          {
            method: "POST",
            body: formData,
          }
        );
        if (response.ok) {
          console.log(response);
          const data = await response.json();
          console.log("API Response:", data);
          setTimeout(() => {
            navigate("/report-table");
          }, 1000);
        } else {
          console.error("API Request Failed:", response.statusText);
        }
      } catch (error) {
        console.error("Error:", error);
        // }
      }
    };

    fetchData();
  }, []);

  const options = {
    items: 1,
    loop: true,
    nav: true,
    dots: true,
    dotsClass: Cx.customDots,
    autoplay: true,
    autoplayTimeout: 1000,
    navText: [false],
    responsive: {
      0: { items: 1 },
      768: { items: 2 },
      1024: { items: 2 },
    },
  };
  /* ======Nwes paper area====== */
  const options1 = {
    items: 1,
    loop: true,
    nav: true,
    dots: true,
    dotsClass: Cx.customDots,
    autoplay: true,
    autoplayTimeout: 10000,
    navText: [false],
    responsive: {
      0: { items: 1 },
      768: { items: 1 },
      1024: { items: 1 },
    },
  };

  const videoRef = useRef(null);

  const [isMuted, setIsMuted] = useState(true);

  const { ref: inViewRef, inView } = useInView({
    threshold: 0.5, // Adjust as needed (e.g., 0.5 means 50% of the video should be in view)
    triggerOnce: false, // Keep observing as the element comes in and out of view
  });

  // Combine refs for videoRef and inViewRef
  const setRefs = (node) => {
    videoRef.current = node;
    inViewRef(node);
  };

  useEffect(() => {
    if (videoRef.current) {
      if (inView) {
        videoRef.current.play().catch((error) => {
          console.warn("Autoplay failed:", error);
        });
        // setIsPlaying(true);
      } else {
        videoRef.current.pause();
        // setIsPlaying(false);
      }
    }
  }, [inView]);

  const handleUserInteraction = () => {
    if (videoRef.current) {
      videoRef.current.muted = false; // Unmute the video
      setIsMuted(false);
      videoRef.current.play().catch((error) => {
        console.warn("Play failed after interaction:", error);
      });
    }
  };

  const handleUnmute = () => {
    if (videoRef.current) {
      videoRef.current.muted = false; // Unmute the video
      setIsMuted(false);
    }
  };

  const openInnerBrowser = (loeLink) => {
    const width = 600;
    const height = 400;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;
    window.open(loeLink, "_blank", `width=${width},height=${height},top=${top},left=${left}`);
  };

  return (
    <>
      {<Headerhome />}
      <section className={Cx.homesection1st}>
        <div className="container px-0">
          <div className="row">
            <div className="col-12">
              <div className={Cx.yourfinance}>
                <h1>Your Financial Journey </h1>
                <h2>Our Expertise.</h2>
                <div className={Cx.pergraph}>
                  <p>
                    &ldquo;Free access to the largest network of lenders in the UK, plus
                    award-winning corporate tax services. Whether you&apos;re an SME or
                    multinational, a sole trader or partnership, an accountant or business
                    consultant, we&apos;re here for your aspirations. &rdquo;
                  </p>
                </div>
                <div className="d-flex justify-content-center">
                  <div className={Cx.searchContainer}>
                    <input
                      type="text"
                      placeholder="Enter your work email for a complimentary discovery call"
                      className={Cx.searchInput}
                    />
                    <button className={Cx.searchButton}>corporate tax relief</button>
                  </div>
                  <div className={Cx.searchphoneview}>
                    <input
                      type="text"
                      placeholder="Enter your work email for a complimentary discovery call"
                      className={Cx.searchInput}
                    />
                    <button className={Cx.searchButton}>corporate tax relief</button>
                  </div>
                </div>
                <small className={Cx.smallText}>
                  Recognised and recommended by The Independent as a company offering
                  &apos;stellar&lsquo; services and products to &apos;improve your business&lsquo;.
                </small>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={Cx.homesection1stphone}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className={Cx.yourfinance}>
                <h1>Your Financial Journey</h1>
                <h2>Our Expertise.</h2>
                <div className={Cx.pergraph}>
                  <p>
                    &ldquo;Free access to the largest network of lenders in the UK, plus
                    award-winning corporate tax services. Whether you&apos;re an SME or
                    multinational, a sole trader or partnership, an accountant or business
                    consultant, we&apos;re here for your aspirations. &rdquo;
                  </p>
                </div>
                <div className="d-flex justify-content-center">
                  <div className={Cx.searchContainer}>
                    <input
                      type="text"
                      placeholder="Enter your work email for a complimentary discovery call"
                      className={Cx.searchInput}
                    />
                    <button className={Cx.searchButton}>corporate tax relief</button>
                  </div>
                  <div className={Cx.searchphoneview}>
                    <input
                      type="text"
                      placeholder="Enter your work email for a complimentary discovery call"
                      className={Cx.searchInput}
                    />
                    <button className={Cx.searchButton}>corporate tax relief</button>
                  </div>
                </div>
                <small className={Cx.smallText}>
                  Recognised and recommended by The Independent as a company offering
                  &apos;stellar&lsquo; services and products to &apos;improve your business&lsquo;.
                </small>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={Cx.homesection8th}>
        <div className="container-fluid px-0 mx-0">
          <div className={Cx.bannerimage} style={{ background: `none` }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-11 col-md-12 mx-auto">
                  <div className={Cx.videowithimage}>
                    <MKBox className={Cx.mainvedio}>
                      <video autoPlay muted loop className={Cx.jomevideoarea}>
                        <source src={videoSource} type="video/mp4" />
                      </video>
                    </MKBox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={Cx.homesection10th}>
        <div className="container-fluid px-0 mx-0">
          <div className={Cx.bannercards}>
            <div className="container">
              <div className="row">
                <div className="col-md-9 col-sm-10 mx-auto mt-md-5 mt-sm-1">
                  <div className={`${Cx.nameimagearea} mb-1`}>
                    <div className="row  mx-auto">
                      <OwlCarousel
                        options={options1}
                        style={{
                          zIndex: "auto",
                        }}
                      >
                        <div className={`${Cx.carouselItem} mx-1`}>
                          <div className={Cx.imgpoint}>
                            <img src={Name1} alt="Logo" />
                          </div>
                        </div>
                        <div className={`${Cx.carouselItem} mx-1`}>
                          <div className={Cx.imgpoint}>
                            <img src={Name2} alt="Logo" />
                          </div>
                        </div>
                      </OwlCarousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={Cx.homesection2nd}>
        <div className="container-fluid px-0 mx-0">
          <div className={Cx.bannertopimage}>
            <div className="container ">
              <div className={Cx.paddingarea}></div>
            </div>
          </div>
          <div className={`${Cx.thereimagepart} pt-0`}>
            <div className="container ">
              <div className={Cx.therepartpadding}>
                <div className="row">
                  <div className="col-12 text-center  mb-4">
                    <h2>Blogs</h2>
                  </div>
                  <div className="col-lg-4  col-md-6 mx-auto">
                    <div className={Cx.blogscard}>
                      <Link to="/research-development">
                        <div className={Cx.imgpointcontent}>
                          <img src={Blog1} alt="Logo" />
                        </div>
                        <p>Research and Development April 2024</p>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mx-auto">
                    <div className={Cx.blogscard}>
                      <Link to="/autumn-budget">
                        <div className={Cx.imgpointcontent}>
                          <img src={Blog2} alt="Logo" />
                        </div>
                        <p>Autumn Budget: Understanding the UK Corporate Tax Roadmap Ahead</p>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mx-auto">
                    <div className={Cx.blogscard}>
                      <Link to="/corporate-reconstructions">
                        <div className={Cx.imgpointcontent}>
                          <img src={Blog3} alt="Logo" />
                        </div>
                        <p>Corporate Reconstructions</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={Cx.homesection3rd}>
        <div className="container-fluid px-0 mx-0">
          <div className={Cx.threetopimage}>
            <div className="container">
              <div className={Cx.paddingarea}></div>
            </div>
          </div>
        </div>
        <div className="container pb-30">
          <div className="row">
            <div className="col-12 mb-5">
              <div className={Cx.hedingbig}>
                <p>Get better results</p>
                <h2>Quick, Easy, Reliable, Free.</h2>
              </div>
            </div>
          </div>
          <div className={Cx.padingstopbottom}>
            <div className="row">
              <div className="col-md-12 col-lg-6  order-md-2 order-2 order-lg-1">
                <div className={Cx.imgpointbig}>
                  <img src={Bigimage1} alt="banner" />
                </div>
              </div>
              <div className="col-md-12 col-lg-6 order-md-1 order-1  order-lg-2 ">
                <div className={Cx.textpartbig}>
                  <h2>01</h2>
                  <h3>Login to AG Finance Hub</h3>
                  <p>
                    The AG Finance Hub is the only platform and mobile app that allows UK businesses
                    to unlock corporate tax relief from start to finish, while also providing UK
                    businesses free access to the UK’s largest network of over 140 lenders for
                    bespoke business financing solutions ranging from £10,000 to £20,000,000.
                  </p>
                  <p>
                    <span>
                      Our network has provided just under £1bn in funding to UK businesses
                    </span>
                  </p>

                  <div className="mt-3">
                    <button
                      type="submit"
                      className={Cx.submitButton2}
                      onClick={() => {
                        openInnerBrowser(
                          "https://calendly.com/agfinancehub/discoverycall",
                          "_blank"
                        );
                      }}
                    >
                      Book a free Discovery Call
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={Cx.padingstopbottom}>
            <div className="row ">
              <div className="col-md-12 col-lg-6 ">
                <div className={Cx.textpartbig}>
                  <h2>02</h2>
                  <h3>Select the service you are looking for.</h3>
                  <p>
                    Tell us if you are looking for business finance or corporate tax relief
                    services. Then, fill out a short discovery questionnaire and book your free
                    discovery call. In the meantime, our team will assess the information you
                    provide to ensure you we can give you the help you need.
                  </p>
                  <p>
                    <span>
                      Free access to the UK’s largest network of over 140 lenders for bespoke
                      business financing solutions ranging from £10,000 to £20,000,000, and, award
                      winning corporate tax services
                    </span>
                  </p>
                  <div className="mt-3">
                    <button
                      type="submit"
                      className={Cx.submitButton2}
                      onClick={() => {
                        openInnerBrowser(
                          "https://calendly.com/agfinancehub/discoverycall",
                          "_blank"
                        );
                      }}
                    >
                      Book a free Discovery Call
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 ">
                <div className={Cx.imgpointbig}>
                  <img src={Bigimage2} alt="banner" />
                </div>
              </div>
            </div>
          </div>

          <div className={Cx.padingstopbottom}>
            <div className="row">
              <div className="col-md-12 col-lg-6  order-md-2 order-2 order-lg-1 ">
                <div className={Cx.imgpointbig}>
                  <img src={Bigimage3} alt="banner" />
                </div>
              </div>
              <div className="col-md-12 col-lg-6 order-md-1 order-1  order-lg-2 ">
                <div className={Cx.textpartbig3}>
                  <h2>03</h2>
                  <h3>Manage everything through the dashboard and receive live updates.</h3>
                  <p>
                    Whether you&apos;re seeking corporate tax services or business finance
                    solutions, our web platform and mobile app streamline the process, making it
                    simple and efficient. Through our dashboard, you can upload financial documents,
                    schedule calls with our team, and complete the technical analysis for R&D
                    claims—all in one place. Plus, you&apos;ll receive live updates to track the
                    status of your work.
                  </p>
                  <p>
                    <span>
                      AG Finance Hub is the UK’s first tech-driven, human-led solution for corporate
                      tax and business finance
                    </span>
                  </p>

                  <div className="mt-3">
                    <button
                      type="submit"
                      className={Cx.submitButton2}
                      onClick={() => {
                        openInnerBrowser(
                          "https://calendly.com/agfinancehub/discoverycall",
                          "_blank"
                        );
                      }}
                    >
                      Book a free Discovery Call
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={Cx.homesection4th}>
        <div className="container">
          <div className={Cx.rightleftsides}>
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className={Cx.leftsidetext}>
                  <h2>With AG Finance Hub, you only pay when we deliver results.</h2>
                  <div className={Cx.peraghraph}>
                    <p>
                      Our platform and mobile app are completely free to use. For business finance
                      solutions, there are no fees, hidden costs, or charges to get you the solution
                      you need, that is a guarantee from us. For our corporate tax relief services,
                      you only pay when a tangible tax benefit is delivered to your company. From
                      start to finish, the entire process is transparent and free of hidden costs,
                      ensuring you receive value before any payment is required.
                    </p>
                  </div>
                  <div className={Cx.unlockthepotential}>
                    <button type="submit" className={Cx.submitButton2}>
                      Unlock the potential of business finance and corporate tax relief
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12 ">
                <div className={Cx.rightsidetext}>
                  <div className={Cx.imgpointbig}>
                    <img src={Aplication} alt="banner" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={Cx.homesection9th}>
        <div className={Cx.videosection}>
          <div className="container">
            <div className="row">
              <div className="col-12 mb-5">
                <div className={Cx.hedingbig}>
                  <p>Testimonial</p>
                  <h2>
                    James Bolton, Operations Director, National Business<br></br> Communications
                  </h2>
                  <h3>
                    &apos;They&apos;ve got access to everybody, they&apos;ve got the whole of market
                    knowledge.... <br></br>they&apos;ve made the process super easy for us....&apos;
                  </h3>
                </div>
              </div>
            </div>
            <div className="row m-auto">
              <div className="col-lg-9 col-md-11  m-auto">
                <div className={Cx.videopart}>
                  <MKBox
                    width="100%"
                    sx={{
                      display: "grid",
                      placeItems: "center",
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <video
                      onClick={handleUserInteraction} // Enable user interaction to unmute and play
                      ref={setRefs} // Use the combined ref
                      autoPlay
                      muted={isMuted}
                      poster={Coverimage}
                      loop
                      className={`${Cx.jomevideoarea} fadeInUp`}
                    >
                      <source src={videoFinance} type="video/mp4" />
                      {/*  Your browser does not support the video tag. */}
                    </video>
                    {isMuted && (
                      <div
                        onClick={handleUnmute}
                        className={Cx.unmuteOverlay}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          color: "#fff",
                          fontSize: "1.5rem",
                          cursor: "pointer",
                        }}
                      >
                        Click to Enable Sound
                      </div>
                    )}
                    {/* <button onClick={togglePlayPause} className={Cx.playPauseButton}>
                      <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
                    </button> */}
                  </MKBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={Cx.homesection7th}>
        <div className="container-fluid px-0 mx-0 fadeInUp">
          <div className="container-fluid px-0 mx-0 ">
            <div className={Cx.bannertopimage}>
              <div className={Cx.paddingarea}></div>
            </div>
          </div>
          <div className={Cx.thereimagepart}>
            <div className="container">
              <div className={Cx.therepartpadding}>
                <div className={Cx.headerpoints}>
                  <h4>Articles</h4>
                </div>
                <div className="row mx-0 px-0">
                  <OwlCarousel
                    options={options}
                    style={{
                      zIndex: "auto",
                    }}
                  >
                    <Link to="/uk-inflation">
                      <div className={Cx.carouselItem}>
                        <div className={Cx.imgpointcontent}>
                          <img src={Name3} alt="Financial Insights" />
                        </div>
                        <p>
                          UK inflation drops to 1.7% - increasing likelihood of further interest
                          rate cuts as inflation dips below BoE target for the first time since
                          2021.
                        </p>
                      </div>
                    </Link>
                    <Link to="/hmrc-right">
                      <div className={Cx.carouselItem}>
                        <div className={Cx.imgpointcontent}>
                          <img src={AiProduct2} alt="Financial Strategy" />
                        </div>
                        <p>Is HMRC always right ?</p>
                      </div>
                    </Link>
                    <Link to="/research-development">
                      <div className={Cx.carouselItem}>
                        <div className={Cx.imgpointcontent}>
                          <img src={Blog1} alt="Logo" />
                        </div>
                        <p>Research and Development April 2024</p>
                      </div>
                    </Link>
                    <Link to="/autumn-budget">
                      <div className={Cx.carouselItem}>
                        <div className={Cx.imgpointcontent}>
                          <img src={Blog2} alt="Logo" />
                        </div>
                        <p>Autumn Budget: Understanding the UK Corporate Tax Roadmap Ahead</p>
                      </div>
                    </Link>
                    <Link to="/corporate-reconstructions">
                      <div className={Cx.carouselItem}>
                        <div className={Cx.imgpointcontent}>
                          <img src={Blog3} alt="Logo" />
                        </div>
                        <p>Corporate Reconstructions</p>
                      </div>
                    </Link>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <MKBox>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default Home;
