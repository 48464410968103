import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Cx from "./page.module.scss";
import Logo from "../../assets/images/AGlogo/AGCtransparent.svg";
import routes from "../../routes";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import LookingForModal from "pages/LandingPages/lookingformodal/LookingFor";

const Headerhome = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [lookingFor, setLookingFor] = useState(false);
  const handleLookingFor = () => setLookingFor(true);
  const handleCloseLookingFor = () => setLookingFor(false);

  const openInnerBrowser = (loeLink) => {
    const width = 600;
    const height = 400;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;
    window.open(loeLink, "_blank", `width=${width},height=${height},top=${top},left=${left}`);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const renderNavLinks = () => {
    return routes.map((route) => {
      if (!route.collapse && (route.name === "My Finance" || route.name === "My Tax")) {
        return (
          <span
            key={route.name}
            onClick={handleLookingFor}
            className={`${Cx.navlink} ${Cx.cursorPointer}`}
          >
            {route.name}
          </span>
        );
      } else if (route.name == "Free Discovery Call") {
        return (
          <span
            key={route.name}
            onClick={() => {
              openInnerBrowser(route?.route, "_blank");
            }}
            className={`${Cx.navlink} ${Cx.cursorPointer}`}
          >
            {route.name}
          </span>
        );
      } else if (!route.collapse) {
        return (
          <Link key={route.name} to={route.route} className={Cx.navlink}>
            {route.name}
          </Link>
        );
      }
      return null;
    });
  };
  /*   const renderNavLinks = () => {
    return routes.map((route) => {
      if (!route.collapse) {
        return (
          <Link key={route.name} to={route.route} className={Cx.navlink}>
            {route.name}
          </Link>
        );
      }
      return null;
    });
  }; */
  const renderDropdown = () => {
    const aboutRoute = routes.find((route) => route.name === "About");

    if (aboutRoute && aboutRoute.collapse) {
      return (
        <>
          <div
            className={Cx.customNavDropdown}
            onMouseEnter={() => setDropdownVisible(true)}
            onMouseLeave={() => setDropdownVisible(false)}
          >
            <Link to={aboutRoute.route} className={Cx.navlink}>
              {aboutRoute.name}
            </Link>
            {dropdownVisible && (
              <div className={Cx.navpoints2}>
                {aboutRoute.collapse.map((subRoute) => (
                  <Link key={subRoute.name} to={subRoute.route} className={Cx.navlink}>
                    {subRoute.name}
                  </Link>
                ))}
              </div>
            )}
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className={`${Cx.mainheader} ${isSticky ? Cx.headerscroll : ""}`}
      >
        <div className="container-fluid">
          <Navbar.Brand href="/" className={Cx.logoimge}>
            <img src={Logo} alt="Logo" />
          </Navbar.Brand>
          <div className={Cx.navbarcollapse}>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" className={Cx.togglebtn} />
            <Navbar.Collapse id="responsive-navbar-nav">
              <div className={Cx.navpoints}>
                {renderNavLinks()}
                {renderDropdown()}
              </div>
            </Navbar.Collapse>
          </div>
        </div>
      </Navbar>

      <Modal
        size="md"
        show={lookingFor}
        centered
        backdrop="static"
        className="modal fade"
        aria-hidden="true"
        onHide={handleCloseLookingFor}
      >
        <div className={Cx.popuarealoking}>
          <Modal.Header closeButton className={Cx.modal_header}></Modal.Header>
          <LookingForModal />
        </div>
      </Modal>
    </>
  );
};

export default Headerhome;
