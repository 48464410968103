import React from "react";
import { Link } from "react-router-dom";
import Cx from "./page.module.scss";

const QuestionInstructionScreen = () => {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-11 m-auto">
            <div className={Cx.instructionspage}>
              <div className={Cx.instructionstop}>
                {/*   <Link to="/dashboard" className={Cx.backbtn}>
                  <IoArrowBackOutline />
                </Link> */}
                <div className={Cx.introText}>
                  <h2>Instructions</h2>
                </div>
              </div>
              <div className={`${Cx.content} mb-5`}>
                <h5>
                  This section is estimated to take 30 minutes. Please provide comprehensive
                  details, using technical and industry-specific terminology to clearly demonstrate
                  the value and merit of your company&apos;s projects. Once all of your projects
                  have been submitted, you will be given the opportunity to upload supporting
                  documents, designs, drawings, reports and any other technical documentation to
                  support your claim.
                </h5>
                <h5>
                  In these next sets of questions, you will be providing information to show how
                  your project satisfies these guidelines, this will include the following:
                </h5>
                <ul className={Cx.list}>
                  <li><span>1.</span> Describing the project&apos;s origin and background.</li>
                  <li>
                    <span>2.</span> Describing the primary fields of science or technology involved (e.g.,
                    engineering, data science, software development, software engineering). If
                    engineering, please specify the type. There can be multiple fields of science
                    and technology a project involves, or just one.
                  </li>
                  <li>
                    <span>3.</span> Explaining the non-straightforward project uncertainties encountered and why
                    they were non-straightforward. For example, the uncertainty may have stemmed
                    from a lack of precedent, a novel challenge, the unique nature of the project,
                    or the unavailability of relevant prior solutions in the public domain (e.g.,
                    due to trade secrets). Complex integrations with diverse components or systems
                    could have also contributed to the uncertainty.
                  </li>
                  <li>
                    <span>4.</span> Explaining the methods used to address these uncertainties and the resulting
                    solutions. Even if unsuccessful or if the advancement was not fully realized,
                    R&D can still be valid. Please include information on any unresolved
                    uncertainties. When talking about the methods, please provide information on any
                    research or feasibility studies the company undertook or any technological or
                    scientific planning and design activities.
                  </li>
                  <li>
                    <span>5.</span> Explaining the baseline (established methods, processes, knowledge, or
                    capability) at project inception and why it was insufficient for a competent
                    professional to readily resolve the uncertainties. Highlight how it was not
                    something that could easily be done.
                  </li>
                  <li>
                    <span>6.</span> Describe the scientific or technological advancements sought, exceeding the
                    existing baseline of knowledge and capability in the relevant field or public
                    domain. This could be new knowledge or capability, or both.
                  </li>
                  <li>
                    When completing the financial exercise, we will provide a clear definition of
                    direct and indirect qualifying activities, allowing you to accurately allocate
                    the staff and subcontractors involved in the project.
                  </li>
                </ul>
                <Link to={"/dashboard"}>
                  If you prefer, you can schedule a technical meeting with our team to discuss this
                  information. To do so, please select &ldquo;Book a Technical Meeting&ldquo; from
                  the dashboard.
                </Link>
              </div>
              <div className="text-center mb-5">
                <Link className={`${Cx.nextbtn} `} to={"/technical-analysis"}>
                  Next
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuestionInstructionScreen;
