// @mui material components

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Cx from "../../../style/custom.module.scss";
import footerRoutes from "footer.routes";
import Headerhome from "layouts/hearderhome/Headerhome";
import DefaultFooter from "examples/Footers/DefaultFooter";

function PrivacyPolicy() {
  return (
    <>
      <Headerhome />
      <section className={`${Cx.section_about} position-relative`}>
        <div className="container-fluid mx-0 px-0">
          <div className="container m-auto">
            <div className={Cx.headerpart}>
              <div className="row m-auto">
                <h1>Privacy Policy</h1>
                <h2>AG Finance and Technology Group Ltd</h2>
                <p>for www.agfinancehub.com and the mobile application ‘AG Finance Hub’</p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className={`${Cx.abutotextarea} ${Cx.terms_policyApplied} pt-0`}>
                <MKTypography variant="body1" className={`${Cx.heading_textarea} pb-4`}>
                  <p className="mt-0">1. <strong>Introduction</strong></p>

                  <div className="ps-4">
                    <p>
                      1. AG Finance and Technology Group Ltd (referred to as &#34;we,&#34; &#34;our,&#34; or &#34;us&#34;) is
                      committed to ensuring the privacy and security of all individuals and businesses
                      interacting with our website and services. This Privacy Policy provides a detailed
                      explanation of the categories of personal data we collect, the purposes for which
                      this data is processed, the legal basis for processing, and the rights of individuals
                      under the applicable data protection laws.
                    </p>
                    <p>
                      2. This Privacy Policy has been created in accordance with the UK General Data Protection Regulation (UK GDPR),
                      the Data Protection Act 2018 (DPA 2018), and all other relevant laws governing the processing of personal data in
                      the United Kingdom. This Policy is designed to ensure transparency, compliance, and ethical management of data.
                    </p>
                    <p>
                      3. By accessing or using our website or services, you acknowledge that you have read, understood, and agreed to the
                      practices described in this Privacy Policy. Your continued use of our website constitutes acceptance of this Policy and our data processing activities.
                    </p>
                    <p>
                      4. This Privacy Policy should be read in conjunction with any specific privacy or fair processing notices provided during your interactions with our
                      services. This Policy is supplementary and does not override such notices.
                    </p>
                    <p>
                      5. Please note that our website may contain links to third-party websites, plug-ins, or platforms. We do not exercise control over these external entities,
                      and we are not responsible for their privacy policies or data practices. Users are encouraged to review the privacy policies of any third-party platforms they engage with.
                    </p>
                  </div>

                </MKTypography>

                <MKTypography variant="body1" className={`${Cx.heading_textarea} pb-4`}>
                  <p>1. <strong>Scope of Application</strong></p>

                  <p className="ps-4">
                    1 This Privacy Policy applies to all data collected, processed, and stored by AG Finance and Technology Group Ltd during the provision of financial facilitation
                    services, corporate tax relief solutions, and other related offerings.
                  </p>

                  <p className="pb-5 ps-4">
                    2 The scope of this Policy includes all interactions conducted through our website, whether by individual entrepreneurs, corporate entities, brokers, lenders, or
                    other third parties whose data may be processed as part of our business activities.
                  </p>

                  <p>2. <strong>Data Collection</strong></p>
                  <p className="ps-4">
                    1. The types of personal data collected depend on your interaction with our website. Categories of data include, but are not limited to:
                  </p>

                  <p>
                    1. Personal Identification Data, which includes full name, address, email address, phone number, date of birth, and other information necessary to identify you.
                  </p>

                  <p>
                    2. Financial Data, encompassing information such as bank statements, VAT returns, management accounts, corporate tax records, and any other financial documentation required to assess eligibility for financing solutions or tax relief services.
                  </p>

                  <p>
                    3. Usage Data, which is automatically collected and relates to your interaction with our website. This data may include your IP address, browser type, operating system, geographical location, referral source, visit duration, pages accessed, and navigation history. Such data is collected using technologies such as cookies and analytics software.
                  </p>

                  <p>
                    4. Technical Data, which pertains to the device used to access our website, including unique device identifiers, diagnostic data, and operating system specifications.
                  </p>

                  <p>
                    5. Communication Data, which includes the content and metadata of communications sent to us via email, telephone, or through our website.
                  </p>

                  <p>
                    6. Marketing Data, which comprises information about your preferences for receiving marketing communications, your interactions with promotional materials, and details regarding any campaigns or offers you engage with.
                  </p>

                  <p className="pt-5">
                    1. In addition to data directly provided by users, we may collect data from third-party sources, including:
                  </p>
                  <p>
                    1. Financial institutions, brokers, or credit reference agencies, to enhance service delivery and support financing solutions.
                  </p>
                  <p>
                    2. Publicly available databases, used for identity verification or eligibility assessments.
                  </p>
                  <p>
                    3. Analytics providers, such as Google Analytics, which help us monitor and improve website performance.
                  </p>
                  <p>
                    1. It is the responsibility of users to ensure that the information they provide is accurate, complete, and up-to-date. The provision of false or misleading information may result in delays or denial of services.
                  </p>

                </MKTypography>
                <MKTypography variant="body1" className={Cx.heading_textarea}>
                  <p>1. <strong>Purpose and Legal Basis for Data Processing</strong></p>

                  <p className="ps-4">
                    1. The processing of personal data by AG Finance and Technology Group Ltd is conducted for specific purposes, each supported by a legal basis under UK GDPR. These purposes include:
                  </p>
                  <p>
                    1. <strong>Provision of Services:</strong>  Personal and financial data are processed to facilitate the delivery of business financing solutions, corporate tax relief services, and related offerings. This includes sharing data with strategic partners, brokers, and lenders to evaluate and provide tailored financing options. The legal basis for such processing is the performance of a contract or steps taken at your request before entering into a contract.
                  </p>
                  <p>
                    2. <strong>Compliance with Legal Obligations:</strong> Data processing is undertaken to fulfill statutory requirements, including compliance with anti-money laundering (AML) regulations, tax laws, and other legal obligations.
                  </p>
                  <p>
                    3. <strong>Legitimate Interests:</strong> Data such as usage information, communication records, and marketing preferences are processed to enhance our website&#39;s functionality, respond to user inquiries, and improve the overall customer experience. This processing is conducted based on legitimate interests, provided these interests do not override your fundamental rights.
                  </p>
                  <p>
                    4. <strong> Consent:</strong> Certain processing activities, such as the delivery of marketing communications or the use of non-essential cookies, are based on your explicit consent. You retain the right to withdraw consent at any time, without affecting the lawfulness of prior processing.
                  </p>
                  <p>
                    5. <strong>Automated Decision-Making and Profiling:</strong> AG Finance and Technology Group Ltd does not engage in automated decision-making processes that produce legal or significant effects on individuals. Profiling activities, if conducted, are subject to human oversight to ensure fairness and transparency.
                  </p>
                  <p className="pb-5">
                    6. <strong>Communication and Notifications:</strong> Personal data, including your mobile number, may be processed to provide notifications, updates, and communications regarding our services. This processing is based on your explicit consent when you provide your mobile number. Users may withdraw consent at any time by contacting us or using the opt-out mechanism provided in such communications.
                  </p>

                  <p>1. <strong>Sharing of Personal Data</strong></p>
                  <p className="ps-4">
                    1. Personal data may be shared with selected third parties under specific circumstances, including but not limited to:
                  </p>
                  <p>
                    1. <strong>Strategic Partners and Lenders:</strong> Financial and technical data provided by users may be shared with brokers, lenders, or financial institutions to assess eligibility for financing options and deliver related services.
                  </p>
                  <p>
                    2. <strong>Third-Party Service Providers:</strong> Data may be processed by entities engaged to provide services such as data hosting, analytics, marketing communication distribution, or customer support. These providers are contractually obligated to maintain the confidentiality and security of the data they process.
                  </p>
                  <p>
                    3. <strong>Regulatory and Legal Authorities:</strong> Personal data may be disclosed to government bodies, regulatory agencies, or law enforcement entities to comply with legal requirements or respond to valid requests.
                  </p>
                  <p>
                    4. <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, user data may be transferred to the new business entity as part of the transaction.
                  </p>
                  <p className="pb-5">
                    1. AG Finance and Technology Group Ltd does not sell user data or share it with third parties for their independent marketing purposes.
                  </p>

                </MKTypography>
                <MKTypography variant="body1" className={Cx.heading_textarea}>
                  <p>1. <strong>Cookies and Tracking Technologies</strong></p>
                  <p className="ps-4">
                    1. Our website uses cookies and similar technologies to enhance functionality, improve user experience, and analyze website performance. Cookies used include:
                  </p>
                  <p>
                    1. Essential Cookies, which are required for the operation of the website.
                  </p>
                  <p>
                    2. Analytics Cookies, which monitor website performance and user behavior to improve services.
                  </p>
                  <p>
                    3. Preference Cookies, which store user preferences and settings.
                  </p>
                  <p>4. Marketing Cookies, which deliver personalized advertising content.</p>

                  <p className="py-5">
                    1. Users can manage or disable cookies through their browser settings. However, disabling cookies may impact website functionality.
                  </p>
                  <p>
                    1. <strong>Data Security</strong>
                  </p>
                  <p>
                    1. AG Finance and Technology Group Ltd employs advanced technical and organizational measures to protect personal data from unauthorized access, loss, or misuse. These measures include encryption, secure data storage, access controls, and regular security assessments.
                  </p>
                  <p>
                    2. In the event of a data breach, affected users will be notified within 72 hours, in accordance with UK GDPR requirements.
                  </p>

                  <p className="pt-5">
                    1. <strong>Data Retention</strong>
                  </p>
                  <p>
                    1. Personal data is retained only for as long as necessary to fulfill the purposes for which it was collected or to comply with legal, regulatory, or contractual obligations.
                  </p>
                  <p>
                    2. Specific retention periods include:
                  </p>
                  <p>
                    1. <strong>Financial Data:</strong> Retained for up to ten years to comply with statutory obligations.
                  </p>
                  <p>
                    2. <strong>Communication Data:</strong> Retained for two years unless part of ongoing agreements.
                  </p>
                  <p className="pb-5">
                    3. <strong>Marketing Data:</strong> Retained until users withdraw consent.
                  </p>
                  <p>
                    1. <strong>Amendments to the Privacy Policy</strong>
                  </p>
                  <p className="ps-4">
                    1. AG Finance and Technology Group Ltd reserves the right to update this Privacy Policy to reflect changes in practices, legal requirements, or technological advancements. Material changes will be communicated to users 30 days prior to taking effect.
                  </p>

                  <p className="pt-5">
                    1. <strong>Governing Law and Jurisdiction</strong>
                  </p>
                  <p className="ps-4">
                    1. This Privacy Policy is governed by the laws of England and Wales.
                  </p>
                  <p className="ps-4 pb-5">
                    2. Any disputes arising under this Policy shall fall under the exclusive jurisdiction of the English courts.
                  </p>
                  <p>
                    1. <strong>Contact Information</strong>
                  </p>
                  <p>
                    For inquiries regarding this Privacy Policy, users may contact <a href="mailto:info@agfinancehub.com"><span>info@agfinancehub.com</span></a>
                  </p>
                  <p>
                    AG Finance and Technology Group Ltd <br />
                    Registered office address 71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ
                  </p>
                </MKTypography>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid px-0 mx-0 ">
          <div className={Cx.bannertopimage1}>
            <div className={Cx.paddingarea1}></div>
          </div>
        </div>
      </section>

      <MKBox px={0}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default PrivacyPolicy;
