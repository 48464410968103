import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Cx from "../../../style/custom.module.scss";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { IoMailOutline } from "react-icons/io5";
import { MdOutlineLocalPhone } from "react-icons/md";
import { HiOutlineUser } from "react-icons/hi";
import { LiaIndustrySolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { registerUserGoogleAndApple } from "../../../redux/slice/authSlice";
import { showErrorToast, showSuccessToast } from "../../../utils/Toster";
import Presentation from "../../../assets/images/sliderOne.jpg";
import Presentation2 from "../../../assets/images/sliderTwo.jpg";
import Presentation3 from "../../../assets/images/sliderThree.jpg";
import Logo from "../../../assets/images/agfinancelogs/splashLogo.png";
import $ from "jquery";
window.$ = window.jQuery = $;
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";
const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dataLocal, setDataLocal] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    const dataLocal = localStorage.getItem("data");
    const newData = JSON.parse(dataLocal);
    setValue("email", newData?.email);
    setValue("full_name", newData?.full_name);
    setDataLocal(newData);
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const userData = {
        email: data?.email,
        mobile_no: data.mobile_no,
        full_name: data?.full_name,
        company_name: data?.company_name,
        industry: data?.industry,
        sso_id: dataLocal?.sso_id,
        onboarding_status: dataLocal?.onboarding_status,
      };
      const actionResult = await dispatch(registerUserGoogleAndApple(userData));
      if (registerUserGoogleAndApple.fulfilled.match(actionResult)) {
        showSuccessToast("Successfully registered");
        if (actionResult?.payload?.id !== null && actionResult?.payload?.id !== undefined) {
          localStorage.setItem("userId", actionResult?.payload?.id);
          localStorage.setItem("email", actionResult?.payload?.email);
          localStorage.removeItem("data");
          navigate("/lookingfor");
          window.location.reload();
        }
      } else {
        showErrorToast(actionResult?.payload?.email[0]);
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      showErrorToast("An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };
  const options = {
    items: 1,
    loop: true,
    nav: true,
    dots: true,
    autoplay: true,
    autoplayTimeout: 3000,
    navText: [false],
    responsive: {
      0: { items: 1 },
      768: { items: 1 },
      1024: { items: 1 },
    },
  };
  return (
    <>
      <section className={Cx.sectionsignup}>
        <div className="container-fluid px-0 mx-0">
          <div className={Cx.containerarea}>
            <div className="row px-0 mx-0">
              <div className="col-md-6 px-0 mx-0">
                <div className={Cx.imagesidesignup}>
                  <div className={Cx.imgarea}>
                    <OwlCarousel options={options}>
                      <div className={Cx.imagepoint}>
                        <img
                          src={Presentation}
                          alt="company_img"
                          className="lazy-img img-fluid"
                          loading="lazy"
                        />
                        <div className={Cx.logoimge}>
                          <img
                            src={Logo}
                            alt="company_img"
                            className="lazy-img img-fluid"
                            loading="lazy"
                          />
                        </div>
                        <div className={Cx.textareasignup}>
                          <h2>Welcome to AG Finance</h2>
                          <p>
                            The world s first tech-led finance and tax solutions company, providing
                            businesses access to the largest network of lenders in the UK, as well
                            as the UK s best tax recovery services.
                          </p>
                        </div>
                      </div>
                      <div className={Cx.imagepoint}>
                        <img
                          src={Presentation2}
                          alt="company_img"
                          className="lazy-img img-fluid"
                          loading="lazy"
                        />
                        <div className={Cx.logoimge}>
                          <img
                            src={Logo}
                            alt="company_img"
                            className="lazy-img img-fluid"
                            loading="lazy"
                          />
                        </div>
                        <div className={Cx.textareasignup}>
                          <h2>Unlock the Power of Business Finance</h2>
                          <p>
                            Bespoke finance solutions for your business from £10,000 to £20,000,000.
                            Our network has delivered just under £1bn to UK businesses.
                          </p>
                        </div>
                      </div>
                      <div className={Cx.imagepoint}>
                        <img
                          src={Presentation3}
                          alt="company_img"
                          className="lazy-img img-fluid"
                          loading="lazy"
                        />
                        <div className={Cx.logoimge}>
                          <img
                            src={Logo}
                            alt="company_img"
                            className="lazy-img img-fluid"
                            loading="lazy"
                          />
                        </div>
                        <div className={Cx.textareasignup}>
                          <h2>Explore Corporate Tax Relief</h2>
                          <p>
                            Identify and utilise tax relief opportunities for your business with our
                            award-winning Tax Recovery Services team.
                          </p>
                        </div>
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-0 mx-0">
                <div className={Cx.ragistermain}>
                  <form onSubmit={handleSubmit(onSubmit)} className={Cx.logingform}>
                    <div className={`${Cx.signtop} ${Cx.extraStyle}`}>
                      <div className={Cx.introText}>
                        <h2>Signup</h2>
                      </div>
                    </div>
                    <div className={Cx.formGroup}>
                      <div className={Cx.inputGroup}>
                        <div className={Cx.icon}>
                          <HiOutlineUser />
                        </div>
                        <input
                          type="text"
                          placeholder="Full Name"
                          {...register("full_name", {
                            required: "Full Name is required",
                          })}
                          readOnly
                        />
                      </div>
                      {errors.full_name && (
                        <p className={Cx.errorMessage}>{errors.full_name.message}</p>
                      )}
                    </div>
                    <div className={Cx.formGroup}>
                      <div className={Cx.inputGroup}>
                        <div className={Cx.icon}>
                          <HiOutlineBuildingOffice2 />
                        </div>
                        <input
                          type="text"
                          placeholder="Company Name"
                          {...register("company_name", {
                            required: "Company Name is required",
                          })}
                        />
                      </div>
                      {errors.company_name && (
                        <p className={Cx.errorMessage}>{errors.company_name.message}</p>
                      )}
                    </div>
                    <div className={Cx.formGroup}>
                      <div className={Cx.inputGroup}>
                        <div className={Cx.icon}>
                          <IoMailOutline />
                        </div>
                        <input
                          type="email"
                          placeholder="Email"
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value: /^[\w.-]+@([\w-]+\.)+\w{2,4}$/,
                              message: "Invalid email address",
                            },
                          })}
                          readOnly
                        />
                      </div>
                      {errors.email && <p className={Cx.errorMessage}>{errors.email.message}</p>}
                    </div>
                    <div className={Cx.formGroup}>
                      <div className={Cx.inputGroup}>
                        <div className={Cx.icon}>
                          <MdOutlineLocalPhone />
                        </div>

                        <input
                          type="tel"
                          placeholder="Mobile Number"
                          {...register("mobile_no", {
                            required: "Mobile number is required",
                            pattern: {
                              value: /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/,
                              message:
                                "Enter a valid UK mobile number (e.g., +44 7******** or  07********)",
                            },
                          })}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^0-9+]/g, "");
                          }}
                        />
                      </div>
                      {errors.mobile_no && (
                        <p className={Cx.errorMessage}>{errors.mobile_no.message}</p>
                      )}
                    </div>
                    <div className={Cx.formGroup}>
                      <div className={Cx.inputGroup}>
                        <div className={Cx.icon}>
                          <LiaIndustrySolid />
                        </div>

                        <input
                          type="text"
                          placeholder="Industry Type"
                          {...register("industry", {
                            required: "Industry is required",
                          })}
                        />
                      </div>
                      {errors.industry && (
                        <p className={Cx.errorMessage}>{errors.industry.message}</p>
                      )}
                    </div>

                    <div className={Cx.mainheading}>
                      <button type="submit" className={Cx.submitButton2} disabled={loading}>
                        {loading ? "Signing Up..." : "Sign Up"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUp;
