import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Cx from "../../../style/custom.module.scss";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import Headerhome from "layouts/hearderhome/Headerhome";

/* import bgImage from "assets/images/bg-about-us.jpg";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes";
import MKButton from "components/MKButton"; */

function About() {
  return (
    <>
      {/* <DefaultNavbar
      routes={routes}
      action={{
        type: "external",
        route: "https://www.creative-tim.com/product/agfinance-hub-react",
        label: "free download",
        color: "default",
      }}
    /> */}
      <Headerhome />

      <section className={`${Cx.bg_Blk_Clr} position-relative`}>
        <MKBox
          width="100%"
          className={`${Cx.section_about}`}
          sx={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={12}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className={Cx.contantabout}
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <div className={`${Cx.main_heading} ${Cx.headerpart}`}>
                <MKTypography>
                  <h1>About US</h1>
                </MKTypography>
                <MKTypography>
                  <h2 style={{ color: '#fff' }}>AG FINANCE AND TECHNOLOGY GROUP LTD</h2>
                </MKTypography>
              </div>
            </Grid>
          </Container>
        </MKBox>
        <div className="container">
          <Card className={`${Cx.bg_Blk_Clr} shadow-none`}>
            <div className={`${Cx.abutotextarea} ${Cx.bg_Blk_Clr}`}>
              <MKTypography variant="body1" className={`${Cx.heading_textarea} pb-5`}>
                <p>
                  ‘AG Finance Hub, created by the AG Finance & Technology Group, is the world’s first
                  and only tech-led finance and tax solutions service. Founded by the Gera brothers,
                  the company offers access to the UK’s largest network of lenders and award-winning
                  tax services for SMEs and multinationals, all at no cost.
                </p>
                <p>
                  Its network has provided just under £1bn in funding to UK businesses and has secured
                  substantial corporate tax relief for eligible UK companies. The AG Finance & Technology
                  Group is pioneering how UK businesses unlock tax relief and business finance. Your needs,
                  their expertise, one destination — AG Finance Hub.’
                  <span>
                    — <i>The Independent</i>
                  </span>
                </p>
              </MKTypography>
              <MKTypography variant="body1" className={`${Cx.heading_textarea} pb-5`}>
                <p><strong>Mission:</strong></p>
                <p className="pb-5">
                  Our mission is to pioneer the way UK businesses unlock tax
                  relief and access business finance. We are developing proprietary
                  AI technology designed to automate, de-risk, and simplify corporate
                  tax and financing processes in the UK.
                </p>
                <p><strong>Services:</strong></p>
                <p>
                  No matter where you are on your business journey, whether you’re just
                  starting out or managing a multimillion-pound empire, we’re here to help.
                  Whether you need access to unsecured financing through a special terms lender
                  for UK tenants or homeowners, secured business loans, asset-backed finance,
                  bridging loans, invoice financing, spot factoring, working capital finance,
                  small business loans (including those for sole traders), property finance
                  (including commercial mortgages), merchant credit advances, or revolving
                  credit facilities—we’ve got you covered.
                </p>
                <p>
                  We also offer a wide range of chartered tax and accounting services. Our award-winning
                  expertise includes R&D Tax Credits (including HMRC enquiries), Capital Allowances, Land
                  Remediation Relief, EIS/SEIS share schemes, and Chartered Tax services.
                </p>
              </MKTypography>
            </div>
          </Card>
        </div>
        <div className="position-relative pt-5">
          <div className="container-fluid px-0 mx-0 ">
            <div className={Cx.bannertopimage1}>
              <div className={Cx.paddingarea1}></div>
            </div>
          </div>
        </div>
      </section>
      <MKBox pt={0} px={0} mt={0}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default About;
