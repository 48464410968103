import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Cx from "./page.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { FiMic, FiMicOff, FiTrash } from "react-icons/fi";
import { PiSpeakerHighDuotone, PiSpeakerSlashDuotone } from "react-icons/pi";
import Header from "layouts/hearder/Header";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import axios from "axios";
import MicRecorder from "mic-recorder-to-mp3";
import { showSuccessToast } from "utils/Toster";
import Sidebar from "layouts/sidebar/sidebar";

const TechnicalAnalysisEdit = () => {
  const [discoveryCallAnswer, setDiscoveryCallAnswer] = useState([]);
  const [recorders, setRecorders] = useState({});
  const [audioPlayers, setAudioPlayers] = useState({});
  const [recordingStatus, setRecordingStatus] = useState({});
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const { control, handleSubmit, setValue, watch } = useForm();
  const projectId = useParams();

  const fetchAnswers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}answers/?user_id=${userId}&category=Technical%20Call%20Questionnaire&project=${projectId?.id}`
      );

      const answers = response?.data?.data.map((item) => ({
        ...item,
        answer: item?.answer?.length ? item?.answer : [""],
      }));
      setDiscoveryCallAnswer(answers);
      answers?.forEach((ans) => {
        ans.answer.forEach((answer_arr, index) => {
          setValue(
            `answer_${ans?.question?.id}_${ans?.id}_${index}`,
            containsAudioFileFormat(answer_arr)
              ? {
                  type: "audio",
                  mic: false,
                  speak: containsAudioFileFormat(answer_arr),
                  delete: containsAudioFileFormat(answer_arr),
                  value: answer_arr,
                  extra: ans,
                }
              : {
                  type: "text",
                  value: answer_arr,
                  extra: ans,
                }
          );
        });
      });
    } catch (error) {
      console.error("Error fetching answers:", error);
    }
  };

  useEffect(() => {
    fetchAnswers();
  }, []);

  // Mic and audio controls
  const handleMicToggle = (item, idx) => {
    const answerIndex = watch(`answer_${item?.question?.id}_${item?.id}_${idx}`);
    if (answerIndex?.mic) {
      stopRecording(item, idx);
    } else {
      startRecording(item, idx);
      setValue(`answer_${item?.question?.id}_${item?.id}_${idx}`, {
        ...answerIndex,
        mic: true,
        speak: false,
        delete: false,
      });
    }
  };

  const handleSpeakToggle = (item, idx) => {
    const answerIndex = watch(`answer_${item?.question?.id}_${item?.id}_${idx}`);
    if (answerIndex?.speak) {
      console.log(answerIndex, "handle SPeak Toggle start");
      startSpeaking(item, idx);
    } else {
      console.log(answerIndex, "handle SPeak Toggle stop");
      stopSpeaking(item, idx);
    }
  };

  const startRecording = (item, idx) => {
    const recorder = new MicRecorder({ bitRate: 128 });
    recorder
      .start()
      .then(() => {
        setRecorders((prevRecorders) => ({
          ...prevRecorders,
          [`answer_${item?.question?.id}_${item?.id}_${idx}`]: recorder,
        }));
      })
      .catch((e) => console.error(e));
  };

  const stopRecording = (item, idx) => {
    const answerIndex = watch(`answer_${item?.question?.id}_${item?.id}_${idx}`);
    recorders[`answer_${item?.question?.id}_${item?.id}_${idx}`]
      ?.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, `question${item?.question?.id}.mp3`, {
          type: blob.type,
          lastModified: Date.now(),
        });
        setValue(`answer_${item?.question?.id}_${item?.id}_${idx}`, {
          ...answerIndex,
          value: file,
          speak: true,
          delete: true,
          mic: false,
        });

        setRecordingStatus((prev) => ({
          ...prev,
          [`answer_${item?.question?.id}_${item?.id}_${idx}`]: "Recording complete!",
        }));
      })
      .catch((e) => console.error(e));
  };

  const startSpeaking = (item, idx) => {
    const answerIndex = watch(`answer_${item?.question?.id}_${item?.id}_${idx}`);
    setValue(`answer_${item?.question?.id}_${item?.id}_${idx}`, {
      ...answerIndex,
      speak: false,
      mic: false,
      delete: true,
    });
    const player = new Audio(answerIndex?.value ?? URL.createObjectURL(answerIndex?.value));
    player.play();
    setAudioPlayers((prev) => ({
      ...prev,
      [`answer_${item?.question?.id}_${item?.id}_${idx}`]: player,
    }));
    player.onended = () => {
      setValue(`answer_${item?.question?.id}_${item?.id}_${idx}`, {
        ...answerIndex,
        speak: true,
        mic: false,
        delete: true,
      });
      setAudioPlayers((prev) => {
        const newState = { ...prev };
        delete newState[`answer_${item?.question?.id}_${item?.id}_${idx}`];
        return newState;
      });
    };
  };

  const stopSpeaking = (item, idx) => {
    const answerIndex = watch(`answer_${item?.question?.id}_${item?.id}_${idx}`);
    const player = audioPlayers[`answer_${item?.question?.id}_${item?.id}_${idx}`];
    if (player) {
      player.pause();
      player.currentTime = 0;
    }
    setValue(`answer_${item?.question?.id}_${item?.id}_${idx}`, {
      ...answerIndex,
      speak: true,
      mic: false,
      delete: true,
    });
  };

  const deleteRecording = (item, idx) => {
    const answerIndex = watch(`answer_${item?.question?.id}_${item?.id}_${idx}`);
    setValue(`answer_${item?.question?.id}_${item?.id}_${idx}`, {
      ...answerIndex,
      value: null,
      mic: false,
      speak: false,
      delete: false,
    });

    setRecordingStatus((prev) => {
      const newStatus = { ...prev };
      delete newStatus[`answer_${item?.question?.id}_${item?.id}_${idx}`];
      return newStatus;
    });
  };

  const uploadAudioFile = async (audioFile) => {
    const formData = new FormData();
    formData.append("audio_file", audioFile);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}audio/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error uploading audio file:", error);
      throw error;
    }
  };

  function containsAudioFileFormat(str) {
    console.log(str, "opopop");
    const audioFileExtensions = [
      ".mp3",
      ".wav",
      ".ogg",
      ".aac",
      ".flac",
      ".m4a",
      ".wma",
      ".aiff",
      ".opus",
    ];
    if (typeof str !== "string") {
      return false; // Return false if str is not a string
    }
    const trimmedStr = str?.trim().toLowerCase();
    return audioFileExtensions.some((ext) => trimmedStr?.endsWith(ext));
  }

  const onSubmit = async (data) => {
    try {
      const answerData = await Promise.all(
        discoveryCallAnswer?.map(async (item) => {
          let final_answer = await Promise.all(
            item.answer.map(async (item_ans, index) => {
              let audioLink = null;
              const question_ans = data[`answer_${item?.question?.id}_${item?.id}_${index}`];
              if (question_ans?.value instanceof File) {
                const s3Link = await uploadAudioFile(question_ans?.value);
                audioLink = s3Link?.audio_file;
                return audioLink;
              } else {
                return question_ans?.value;
              }
            })
          );

          return {
            id: item?.id,
            user: userId,
            question: item?.question?.id,
            project: projectId?.id,
            answer: final_answer,
          };
        })
      );
      const ansFilterData = answerData?.map((item) => ({
        ...item,
        answer: Array.isArray(item?.answer) && item?.answer[0] === "" ? [] : item?.answer,
      }));

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}bulkupdateanswer/`,
        ansFilterData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response) {
        navigate("/dashboard");
        showSuccessToast(response.data.message);
        fetchAnswers();
      }
    } catch (error) {
      console.error("Error submitting answers:", error);
    }
  };

  return (
    <>
      <Header activeClass="Technical Call Questions" />
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3" style={{ paddingLeft: "0px", top: "-3px" }}>
              <Sidebar activeClass="Technical Call Questions" />
            </div>
            <div className="col-lg-9">
              <div className={Cx.mainheadingUploaddocument}>
                <h2>
                  Let’s do some technical analysis by answering a few questions! (must be completed
                  in one go)
                </h2>
                <form className={Cx.technicalanalysisForm} onSubmit={handleSubmit(onSubmit)}>
                  {discoveryCallAnswer &&
                    discoveryCallAnswer
                      ?.sort((a, b) => a.question?.order - b.question?.order)
                      ?.filter((item) => item?.question?.order !== 22)
                      .map((item, index) => {
                        return (
                          <div className={Cx.formGroup} key={index}>
                            <label htmlFor={`income${item?.question?.id}_${item?.id}_${index}`}>
                              <span>
                                Q.{item?.question?.order}.
                                {item?.question?.partno === 1 ? "" : item?.question?.partno}
                              </span>
                              {item?.question?.question}
                            </label>
                            {item?.question?.responseType == "Mcq" && (
                              <div className={Cx.customTextarea}>
                                <div className={Cx.radioGroup}>
                                  <label>
                                    <input
                                      type="checkbox"
                                      name={item?.question?.choice1}
                                      value={item?.question?.choice1}
                                      id="html"
                                      style={{ width: "20px" }}
                                      checked={discoveryCallAnswer[11]?.answer[0]?.includes(
                                        item?.question?.choice1
                                      )}
                                      // onChange={(e) => handleTextChange(index, e)}
                                    />
                                    <span>{item?.question?.choice1}</span>
                                  </label>
                                </div>
                                <div className={Cx.radioGroup}>
                                  <label>
                                    <input
                                      type="checkbox"
                                      name={item?.question?.choice2}
                                      value={item?.question?.choice2}
                                      id="html"
                                      style={{ width: "20px" }}
                                      checked={discoveryCallAnswer[11]?.answer[0]?.includes(
                                        item?.question?.choice2
                                      )}
                                      // onChange={(e) => handleTextChange(index, e)}
                                    />
                                    <span>{item?.question?.choice2}</span>
                                  </label>
                                </div>
                                <div className={Cx.radioGroup}>
                                  <label>
                                    <input
                                      type="checkbox"
                                      name={item?.question?.choice3}
                                      value={item?.question?.choice3}
                                      id="html"
                                      style={{ width: "20px" }}
                                      checked={discoveryCallAnswer[11]?.answer[0]?.includes(
                                        item?.question?.choice3
                                      )}
                                      // onChange={(e) => handleTextChange(index, e)}
                                    />
                                    <span>{item?.question?.choice3}</span>
                                  </label>
                                </div>
                                <div className={Cx.radioGroup}>
                                  <label>
                                    <input
                                      type="checkbox"
                                      name={item?.question?.choice4}
                                      value={item?.question?.choice4}
                                      id="html"
                                      style={{ width: "20px" }}
                                      checked={discoveryCallAnswer[11]?.answer[0]?.includes(
                                        item?.question?.choice4
                                      )}
                                      // onChange={(e) => handleTextChange(index, e)}
                                    />
                                    <span>{item?.question?.choice4}</span>
                                  </label>
                                </div>
                              </div>
                            )}

                            {item?.answer?.map((ansq_arr, ansq_index) => {
                              return (
                                <div className={Cx.inputGroup} key={ansq_index}>
                                  <>
                                    {item?.question?.responseType == "Text/Audio" && (
                                      <Controller
                                        name={`answer_${item?.question?.id}_${item?.id}_${ansq_index}`}
                                        control={control}
                                        defaultValue={
                                          containsAudioFileFormat(ansq_arr)
                                            ? ""
                                            : typeof ansq_arr !== "string"
                                            ? ""
                                            : ansq_arr ?? ansq_arr
                                        }
                                        render={({ field }) => {
                                          const fieldValue = field?.value || {
                                            type: "text",
                                            value: [""],
                                          };
                                          const fieldValueArray = fieldValue?.value ?? [""];

                                          return (
                                            <>
                                              <div>
                                                <textarea
                                                  {...field}
                                                  id={`income${item?.question?.id}_${item?.id}_${ansq_index}`}
                                                  placeholder={item?.question?.question}
                                                  value={
                                                    containsAudioFileFormat(fieldValueArray)
                                                      ? ""
                                                      : typeof fieldValueArray !== "string"
                                                      ? ""
                                                      : fieldValueArray ?? fieldValueArray
                                                  }
                                                  onChange={(event) => {
                                                    field.onChange({
                                                      ...fieldValue,
                                                      value: event.target.value,
                                                    });
                                                  }}
                                                />
                                                <div className={Cx.toggleIcon}>
                                                  <div
                                                    onClick={() =>
                                                      handleMicToggle(item, ansq_index)
                                                    }
                                                  >
                                                    {watch(
                                                      `answer_${item?.question?.id}_${item?.id}_${ansq_index}`
                                                    )?.mic ? (
                                                      <FiMic />
                                                    ) : (
                                                      <FiMicOff />
                                                    )}
                                                  </div>
                                                  {watch(
                                                    `answer_${item?.question?.id}_${item?.id}_${ansq_index}`
                                                  )?.delete && (
                                                    <div
                                                      onClick={() =>
                                                        handleSpeakToggle(item, ansq_index)
                                                      }
                                                    >
                                                      {watch(
                                                        `answer_${item?.question?.id}_${item?.id}_${ansq_index}`
                                                      )?.speak ? (
                                                        <PiSpeakerSlashDuotone />
                                                      ) : (
                                                        <PiSpeakerHighDuotone />
                                                      )}
                                                    </div>
                                                  )}
                                                  {watch(
                                                    `answer_${item?.question?.id}_${item?.id}_${ansq_index}`
                                                  )?.delete && (
                                                    <div
                                                      onClick={() =>
                                                        deleteRecording(item, ansq_index)
                                                      }
                                                    >
                                                      <FiTrash />
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }}
                                      />
                                    )}
                                  </>
                                </div>
                              );
                            })}

                            {recordingStatus[index] && <p>{recordingStatus[index]}</p>}
                          </div>
                        );
                      })}
                  <div className={Cx.mainheading}>
                    <div className="mb-5">
                      <button type="submit" className={Cx.submitButton2}>
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div>
          <DefaultFooter content={footerRoutes} />
        </div>
      </section>
      {/*    <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox> */}
    </>
  );
};

export default TechnicalAnalysisEdit;
