import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Cx from "../../../style/custom.module.scss";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import Headerhome from "layouts/hearderhome/Headerhome";

/* import bgImage from "assets/images/bg-about-us.jpg";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes";
import MKButton from "components/MKButton"; */

const ResearchDevelopment = () => {
  return (
    <>
      {/* <DefaultNavbar
      routes={routes}
      action={{
        type: "external",
        route: "https://www.creative-tim.com/product/agfinance-hub-react",
        label: "free download",
        color: "default",
      }}
    /> */}
      <Headerhome />

      <section className={`${Cx.bg_Blk_Clr} position-relative`}>
        <MKBox
          width="100%"
          className={`${Cx.section_about}`}
          sx={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={12}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className={Cx.contantabout}
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <div className={`${Cx.main_heading} ${Cx.headerpart}`}>
                <MKTypography>
                  <h1>Research & Development April 2024</h1>
                </MKTypography>
                <MKTypography>
                  <h2 style={{ color: '#fff' }}>AG FINANCE AND TECHNOLOGY GROUP LTD</h2>
                </MKTypography>
              </div>
            </Grid>
          </Container>
        </MKBox>
        <div className="container">
          <Card className={`${Cx.bg_Blk_Clr} shadow-none `}>
            <div className={`${Cx.abutotextarea} ${Cx.bg_Blk_Clr}`}>
              <div className={`${Cx.heading_textarea} pb-5`}>
                <p>
                  In the 2023 Autumn Statement, adjustments were outlined to simplify and enhance the R&D
                  tax incentive program in the UK. The following amendments came into force from April 1, 2024.
                  <b>
                    At the bottom we have included the most notable changes from the 2024 Autumn Statement, namely
                    to the Updates on R&D Intensive Scheme (RDIS).
                  </b>
                </p>
                <p>
                  The budget made clarifications on the R&D Intensive Scheme (RDIS), a tax relief program designed
                  to encourage significant investment in research and development. Initially, the Finance Act 2024
                  excluded certain R&D expenditure from counting toward the R&D intensity threshold, which determines
                  a company&#39;s eligibility for RDIS. However, recent budget documentation confirmed that Research &
                  Development Expenditure Credit (RDEC) qualified spending will indeed be included in the intensity
                  calculation, effective from April 1, 2023.
                </p>
                <p>
                  For businesses aiming to qualify under the Enhanced R&D Intensive Support (ERIS), the required threshold
                  for R&D intensity will be reduced from <b>40% to 30%</b> starting April 1, 2024. This reduction makes it easier
                  for companies to qualify as R&D intensive, thus expanding access to these valuable tax incentives.
                </p>
                <p>
                  The most significant proposed alteration involves merging the SME and RDEC schemes into a single streamlined scheme,
                  enabling all companies, regardless of size, to claim under the same framework. This adjustment applies to accounting
                  periods commencing on or after April 1, 2024, impacting companies with accounting periods ending on March 31, 2025, initially.
                </p>
                <p>
                  For companies presently utilising the SME scheme, there will be modifications to how R&D relief is treated. Currently, it is
                  accounted for &#39;below the line,&#39; below the profit before tax. However, under the unified scheme, the R&D credit will be recognised
                  &#39;above the line&#39; as other income. This change is due to the RDEC credit being subject to corporation tax, necessitating its inclusion
                  within the profit before tax figure and the merged scheme will follow this methodology.
                </p>
                <p>
                  The following table delineates these modifications and their financial implications for future claims.
                </p>
                <p>
                  *From April 1, 2023, the post-tax RDEC/Merged scheme rates will vary based on a company&#39;s taxable profits and the applicable corporation
                  tax rate. At the standard corporate tax rate of 25%, the Net RDEC stands at 15%, while for small companies at a rate of 19%, it is 16.2%.
                  For companies in the marginal rate band, which pays tax at 26.5%, the rate is 14.7%.
                </p>
                <p>
                  **Loss-making R&D intensive companies refers to those companies where qualifying R&D expenditure constitutes a minimum of 40% (effective
                  from April 1, 2023) or 30% (applicable to accounting periods commencing on or after April 1, 2024) of total expenditure (splitting accounting
                  periods as necessary). Total expenditure is calculated based on the total expenses figure in the profit and loss (P&L) account, amended to reflect
                  expenses incurred pursuant to section 1308 of the Corporation Tax Act (CTA) 2009 and deducting any non-deductible amounts for Corporation Tax purposes.
                </p>
                <p>
                  For the tax years 2022/23 and 2023/24, if your year-end spans 1st April , you will need to perform a split period calculation for R&D costs to ensure the
                  correct relief rates are applied. In instances where apportioning costs proves challenging, HMRC may consider accepting a blended rate of relief for a period
                  spanning a rate change, provided it does not materially affect your claims.
                </p>

                <p className="pt-5"><strong>Changes to Claiming Overseas Costs</strong></p>
                <p>
                  Starting from 1 April, 2024, R&D must physically occur within the UK for associated expenses to be eligible for R&D tax relief claims. UK-based companies currently including R&D costs paid to overseas entities or third parties may find these costs excluded from their claims. Expenses related to externally provided workers (EPWs) will be restricted to work conducted within the UK, specifically for workers compensated under the PAYE/NIC scheme.
                </p>
                <p>
                  Exceptions exist for work conducted outside the UK due to geographical, environmental, social, or regulatory/legal obligations. Claims cannot be made for overseas costs if work is conducted abroad due to economic reasons i.e. cost limitations or the lack of availability of suitable competent professionals in the UK.
                </p>
                <p>
                  The international structures of businesses conducting R&D present various considerations. For instance, a UK-based group may operate a branch overseas where UK R&D projects are conducted. If all intellectual property (IP) remains in the UK and profits from the foreign branch are taxed in the UK, staffing costs for &#34;overseas&#34; work can still qualify for UK R&D relief purposes.
                </p>
                <p>
                  On the other hand, if an international company assigns a worker to a UK group entity for a UK R&D project, but the worker&#39;s salary is paid by the foreign company (reimbursed to the UK company at a markup), these salary costs would not qualify for UK R&D relief unless the worker is subject to UK PAYE and NIC.
                </p>

                <p className="pt-5"><strong>Changes to R&D Intensive Companies</strong></p>
                <p>
                  Previously, it was announced that SMEs with R&D spending constituting at least 40% of total expenditure, starting from April 1, 2023, would continue receiving relief in the form of an SME tax credit, providing an effective relief rate of 26.97% of allowable R&D expenditure.
                </p>
                <p>
                  However, there have been adjustments to the R&D Intensive rules:
                </p>
                <p>
                  The R&D &#39;intensity&#39; threshold will decrease from 40% to 30% for accounting periods starting on or after April 1, 2024.
                </p>
                <p>
                  A one-year grace period will be implemented if a company&#39;s R&D intensity drops below 30%. This accommodation will assist companies facing fluctuations in expenditure, ensuring consistency in reporting R&D tax relief in their accounts.
                </p>
                <p>
                  This separate scheme will operate alongside the newly unified scheme mentioned earlier, with efforts made to align the rules as closely as possible. Relief under this standalone scheme will be reflected in the tax section of claimant companies&#39; accounts.
                </p>

                <p className="pt-5">
                  <strong>***Autumn 2024: Updates on R&D Intensive Scheme (RDIS)</strong>
                </p>
                <p>
                  The budget made clarifications on the R&D Intensive Scheme (RDIS), a tax relief program designed to encourage significant investment in research and development. Initially, the Finance Act 2024 excluded certain R&D expenditure from counting toward the R&D intensity threshold, which determines a company’s eligibility for RDIS. However, recent budget documentation confirmed that Research & Development Expenditure Credit (RDEC) qualified spending will indeed be included in the intensity calculation, effective from April 1, 2023.
                </p>
                <p>
                  For businesses aiming to qualify under the Enhanced R&D Intensive Support (ERIS), the required threshold for R&D intensity will be reduced from <b>40% to 30%</b> starting April 1, 2024. This reduction makes it easier for companies to qualify as R&D intensive, thus expanding access to these valuable tax incentives.
                </p>

                <p className="pt-5"><strong>Ability to Amend Claims</strong></p>
                <p>
                  One advantageous aspect of the consolidated R&D schemes is that the newly unified scheme will embrace the revision rules of the RDEC regime.
                </p>
                <p>
                  If HMRC questions a claim under the RDEC regime or issues a tax assessment against a company for RDEC, there is a window of 30 days to submit an amended claim. This ensures that errors in a claim do not forfeit the right to rectify it.
                </p>
                <p>
                  Likewise, if an R&D claim was inaccurately filed under the R&D SME scheme and HMRC rejects it after the time limit for amending claims has elapsed, the company can still make a claim under the RDEC scheme.
                </p>

                <p className="pt-5"><strong>Summary</strong></p>
                <p>
                  The changes announced represent a significant shift in the UK’s approach to R&D with an overall reduction to the relief available and more rigorous documentation required to support the claim.
                </p>
                <p>
                  It is therefore vital to engage a competent and suitably qualified tax adviser to assist you with making your claim.
                </p>
                <p>
                  Our careful and detail oriented approach by qualified advisers on both the technical and tax aspects mean that your claim will be in safe hands.
                </p>
                <p>
                  This article was written by our Tax Partner Ben Hawkins, a qualified Chartered Accountant and Chartered Tax Adviser with more than ten years of experience in UK corporate tax. Ben can offer assistance with more than just R&D claims, with expertise in areas such as EIS/SEIS, share schemes, capital allowances and corporate reconstructions.
                </p>
                <p>
                  If you would be interested in a free, no obligation consultation with Ben, please email <a href="mailto:info@levygera.com">info@levygera.com.</a>
                </p>

              </div>
            </div>
          </Card>
        </div>
        <div className="position-relative pt-5">
          <div className="container-fluid px-0 mx-0 ">
            <div className={Cx.bannertopimage1}>
              <div className={Cx.paddingarea1}></div>
            </div>
          </div>
        </div>
      </section>

      <MKBox pt={0} px={0} mt={0}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default ResearchDevelopment;
