import React, { useEffect, useState } from "react";
import Cx from "./page.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { MdOutlineFileUpload } from "react-icons/md";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import MKBox from "components/MKBox";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import { FaRegEye } from "react-icons/fa";
import { HiOutlineTrash } from "react-icons/hi";
import axios from "axios";
import Headerhome from "layouts/hearderhome/Headerhome";

const UploadDocument = () => {
  const [userId] = useState(localStorage.getItem("userId"));
  const [selectedFiles, setSelectedFiles] = useState({
    filedAccounts: null,
    filedCT600: null,
    taxComputation: null,
  });
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [linkData, setLinkData] = useState();

  // const navigate = useNavigate();

  const handleFileChange = (event, fileType) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFiles((prevFiles) => ({
        ...prevFiles,
        [fileType]: file,
      }));
    }
  };

  const handleViewFile = (fileUrl) => {
    if (fileUrl instanceof File) {
      const generatedUrl = URL.createObjectURL(fileUrl);
      window.open(generatedUrl, "_blank");
    } else {
      window.open(fileUrl, "_blank");
    }
  };

  const handleRemoveFile = (fileType) => {
    setSelectedFiles((prevFiles) => ({
      ...prevFiles,
      [fileType]: null,
    }));
  };

  const onQuationHandler = async () => {
    try {
      if (!userId) {
        console.log("User ID not found");
        return;
      }

      if (
        !selectedFiles.filedAccounts &&
        !selectedFiles.filedCT600 &&
        !selectedFiles.taxComputation
      ) {
        console.log("No files selected");
        return;
      }

      setLoading(true);
      const formData = new FormData();
      if (selectedFiles.filedAccounts) {
        formData.append("Accounts", selectedFiles.filedAccounts);
      }
      if (selectedFiles.filedCT600) {
        formData.append("CT600", selectedFiles.filedCT600);
      }
      if (selectedFiles.taxComputation) {
        formData.append("TaxComputation", selectedFiles.taxComputation);
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}user/${userId}/`,
        formData,
        config
      );

      if (response?.status === 200) {
        setOpen(true);
        console.log("Files uploaded successfully");
      } else {
        console.error("Error uploading files");
      }
    } catch (error) {
      console.error("Error during file upload:", error);
    } finally {
      setLoading(false);
    }
  };

  const onCalendarHandler = async () => {
    const apiMeetingData = await axios.get(`${process.env.REACT_APP_BASE_URL}calendly/`);
    const meetingData = apiMeetingData?.data;
    const discoveryCallLink = meetingData?.find((item) => item?.type === "discovery");
    const linkData = discoveryCallLink?.link;
    setLinkData(linkData);
  };

  useEffect(() => {
    onCalendarHandler();
  }, []);

  return (
    <>
      <Headerhome />
      <section>
        <div className="container">
          <div className="row mt-10">
            <div className="col-lg-8 m-auto">
              <div className={Cx.whatarelooking}>
                <div className={Cx.mainheadingtop}>
                  <h1>Upload Documents</h1>
                </div>
                <div className={Cx.uploaddocumentForm}>
                  {/* Filed Accounts */}
                  <div className={Cx.accounts}>
                    <h2>Filed Accounts</h2>
                    <div className={Cx.fileActions}>
                      {selectedFiles.filedAccounts ? (
                        <div className={Cx.iconsponts}>
                          <span
                            onClick={() => handleViewFile(selectedFiles?.filedAccounts)}
                            className={Cx.iconcircel}
                          >
                            <FaRegEye />
                          </span>
                          <span onClick={() => handleRemoveFile("filedAccounts")}>
                            <HiOutlineTrash />
                          </span>
                        </div>
                      ) : null}
                      <button
                        type="button"
                        className={Cx.uloadbtn}
                        onClick={() => document.getElementById("filedAccountsInput").click()}
                      >
                        <MdOutlineFileUpload />
                      </button>
                      <input
                        id="filedAccountsInput"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => handleFileChange(e, "filedAccounts")}
                      />
                    </div>
                  </div>

                  {/* Filed CT600 */}
                  <div className={Cx.accounts}>
                    <h2>Filed CT600</h2>
                    <div className={Cx.fileActions}>
                      {selectedFiles.filedCT600 ? (
                        <div className={Cx.iconsponts}>
                          <span
                            onClick={() => handleViewFile(selectedFiles.filedCT600)}
                            className={Cx.iconcircel}
                          >
                            <FaRegEye />
                          </span>
                          <span onClick={() => handleRemoveFile("filedCT600")}>
                            <HiOutlineTrash />
                          </span>
                        </div>
                      ) : null}
                      <button
                        type="button"
                        className={Cx.uloadbtn}
                        onClick={() => document.getElementById("filedCT600Input").click()}
                      >
                        <MdOutlineFileUpload />
                      </button>
                      <input
                        id="filedCT600Input"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => handleFileChange(e, "filedCT600")}
                      />
                    </div>
                  </div>

                  {/* Tax Computation */}
                  <div className={Cx.accounts}>
                    <h2>Tax Computation</h2>
                    <div className={Cx.fileActions}>
                      {selectedFiles.taxComputation ? (
                        <div className={Cx.iconsponts}>
                          <span
                            onClick={() => handleViewFile(selectedFiles.taxComputation)}
                            className={Cx.iconcircel}
                          >
                            <FaRegEye />
                          </span>
                          <span onClick={() => handleRemoveFile("taxComputation")}>
                            <HiOutlineTrash />
                          </span>
                        </div>
                      ) : null}
                      <button
                        type="button"
                        className={Cx.uloadbtn}
                        onClick={() => document.getElementById("taxComputationInput").click()}
                      >
                        <MdOutlineFileUpload />
                      </button>
                      <input
                        id="taxComputationInput"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => handleFileChange(e, "taxComputation")}
                      />
                    </div>
                  </div>

                  {/* Submit Button */}
                  <div className={Cx.mainheading}>
                    <button
                      onClick={() => {
                        onQuationHandler();
                        setOpen(true);
                      }}
                      className={Cx.submitButton}
                    >
                      {loading ? "Uploading..." : "Upload"}
                    </button>

                    {open && (
                      <Modal
                        open={open}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box className={Cx.popuarea}>
                          <Typography>
                            <Link className={Cx.linkbtn2} to="/dashboard">
                              We have received your Discovery questionnaire. Our team will soon
                              connect with you.
                            </Link>
                            <br />
                            Or
                            <p>
                              <Link className={Cx.linkbtn2} to={linkData} target="_blank">
                                You can book a discovery call by clicking here !
                              </Link>
                            </p>
                          </Typography>
                        </Box>
                      </Modal>
                    )}

                    <div className={Cx.mainheading}>
                      <h3>Don&apos;t have these to hand?</h3>
                      <Link to={linkData} className={Cx.linkbtn}>
                        That&apos;s okay, just click &apos;Complete&apos; to book Your discovery
                        call.
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <MKBox pt={2} mt={2}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default UploadDocument;
