import React, { useEffect, useState, useMemo } from "react";
import Cx from "./page.module.scss";
import Box from "@mui/material/Box";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import Header from "layouts/hearder/Header";
import Sidebar from "layouts/sidebar/sidebar";

const steps = {
  "Estimated Tax Benefit £15,000 ": "estimated_tax_benefit",
  "AML Check Complete": "aml_check_complete",
  "Preparing Your Tax Relief Claim": "preparing_tax_claim",
  "Financial And Technical Elements Completed": "financial_and_technical_completed",
  "Final Sign Off Ready For Client Review": "final_sign_off_ready",
  "Final Sign Off Sent": "final_sign_off_sent",
  "Claim Filed": "claim_field",
  "Submission Receipt": "submission_receipt",
  "Claim Being Processed By HMRC": "claim_being_processed",
  "Claim Processed By HMRC": "claim_processed",
};

const businessSteps = {
  "Discovery Call": "discovery_call",
  "AML Check Complete": "aml_check_complete",
  "Business Finance Review": "business_finance_review",
  "Documents Provided": "documents_provided",
  "Application Submitted": "application_submitted",
  "Options Presented": "options_presented",
  "Business Finance Confirmed": "business_finance_confirmed",
  "T's & C's Signed": "ts_and_cs_signed",
  "Finance Delivered": "finance_delivered",
};

const ApplicationStatus = () => {
  const [activeStatus, setActiveStatus] = useState(null);
  const userRole = localStorage.getItem("UserType");
  const userId = localStorage.getItem("userId");

  const fetchData = async () => {
    if (!userId) {
      console.error("User ID not found");
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}user/${userId}`);
      console.log("response", response);
      if (response.ok) {
        const data = await response.json();
        console.log("data>>>>>>>>>>>>", data);
        const backendStatus = userRole === "is_business" ? data?.business_status : data?.tax_status;
        const displayStatus = Object.keys(getSteps).find((key) => getSteps[key] === backendStatus);
        setActiveStatus(displayStatus);
      } else {
        console.error("Failed to fetch user data", response.statusText);
      }
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getSteps = useMemo(() => {
    return userRole === "is_business" ? businessSteps : steps;
  }, [userRole]);

  return (
    <>
      <Header activeClass="ApplicationStatus" />
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3" style={{ paddingLeft: "0px", top: "-3px" }}>
              <Sidebar activeClass="ApplicationStatus" />
            </div>
            <div className="col-lg-9">
              <div className={Cx.applicationStatuspage}>
                <div className={Cx.technicalanalysis}>
                  {/*    <Link
                    to={userRole === "is_business" ? "/dashboard-business" : "/dashboard"}
                    className={Cx.backbtn}
                  >
                    <IoArrowBackOutline />
                  </Link> */}
                  <div className={Cx.introText}>
                    <h2>Application Status</h2>
                  </div>
                </div>
                <Box className={Cx.termsconditionsForm}>
                  <div className={Cx.applicationStatusContainer}>
                    <div className={Cx.statusList}>
                      {Object.keys(getSteps).map((status) => (
                        <div
                          key={status}
                          className={`${Cx["status-item"]} ${
                            activeStatus === status ? Cx.active : ""
                          }`}
                        >
                          <div className={Cx["status-circle"]}></div>
                          <span style={{ paddingLeft: "2px" }}>{status}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </div>
        <div>
          <DefaultFooter content={footerRoutes} />
        </div>
      </section>
      {/*  <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox> */}
    </>
  );
};

export default ApplicationStatus;
